import React from "react";
import {
  changeItemSelectedFuncInputPlaceholder,
  changeItemSelectedFuncInputValue,
  changeItemSelectedFuncInputValue2,
  toggleInputValueAsFuncPrice,
  toggleMultipleOfInputValueAsFuncPrice,
} from "../../../../../../redux/dapp";
import ConfigCheckMarkWithSentence from "../configCheckMarkWithSentence/ConfigCheckMarkWithSentence";
import ConfigInput from "../configInput/ConfigInput";
import "./configItemInputs.css";

export default function ConfigItemInputs({ block, section, item }) {
  const displayInputs = item.selectedFunc.inputs.map((input, idx) => {
    return (
      <div key={idx}>
        <ConfigInput
          label={`Placeholder ${idx + 1}`}
          type="text"
          value={input.placeholder}
          onChangeFunc={changeItemSelectedFuncInputPlaceholder}
          onChangeFuncParams={{
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            inputId: input.id,
          }}
          disabled={false}
        />
        {item.selectedFunc.stateMutability === "payable" && (
          // item.selectedFunc.price.isInputDependant.type === ""[]
          <>
            <ConfigCheckMarkWithSentence
              boolValue={
                item.selectedFunc.price.isInputDependant.type === "inputValue"
              }
              func={toggleInputValueAsFuncPrice}
              funcParams={{
                sectionId: section.id,
                blockId: block.id,
                itemId: item.id,
                input: input,
                toggleValue: !item.selectedFunc.price.isInputDependant.value,
                type:
                  item.selectedFunc.price.isInputDependant.type === "inputValue"
                    ? ""
                    : "inputValue",
              }}
              sentence="Set this input value as the function price"
              input={input}
            />
            <ConfigCheckMarkWithSentence
              boolValue={
                item.selectedFunc.price.isInputDependant.type ===
                "multipleOfInputValue"
              }
              func={toggleInputValueAsFuncPrice}
              funcParams={{
                sectionId: section.id,
                blockId: block.id,
                itemId: item.id,
                input: input,
                toggleValue: !item.selectedFunc.price.isMultipleOfInputValue,
                type:
                  item.selectedFunc.price.isInputDependant.type ===
                  "multipleOfInputValue"
                    ? ""
                    : "multipleOfInputValue",
              }}
              sentence="Multiply function price by the value of this input"
              input={input}
            />
          </>
        )}
      </div>
    );
  });

  return <div className="configItemInputs">{displayInputs}</div>;
}
