import React from "react";
import "./fileInput.css";
import Spinner from "react-bootstrap/Spinner";

export default function FileInput({ onChangeFunc, loading, hasImage }) {
  return (
    <>
      {!loading ? (
        <input
          className={"custom-file-input" + ` ${hasImage}`}
          type="file"
          onChange={(e) => {
            if (e.target.files[0]) {
              onChangeFunc(e.target.files[0]);
            }
          }}
        />
      ) : (
        <button>
          <Spinner animation="border" />
        </button>
      )}
    </>
  );
}
