import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  changeDynamicContractDataItemColor,
  changeDynamicContractDataItemSelectedFunc,
  changeDynamicContractDataItemText,
  deleteItem,
  toggleItemConfigContractsViewActive,
} from "../../../../../../../../../redux/dapp";
import ChevronCircleState from "../../../../../../../../icons/chevronCircleState/ ChevronCircleState";
import TrashCan from "../../../../../../../../icons/trashCan/TrashCan";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import ConfigContractsAndFunctionsModal from "../../../../configContractsAndFunctionsModal/ConfigContractsAndFunctionsModal";
import ConfigInput from "../../../../configInput/ConfigInput";
import ConfigLabelWithComp from "../../../../ConfigLabelWithComp/ConfigLabelWithComp";
import ConfigOptionWithIcon from "../../../../configOptionWithIcon/ConfigOptionWithIcon";
import ConfigRightItemRow from "../../../../configRightItemRow/ConfigRightItemRow";
import "./dynamicContractDataItemConfig.css";

export default function DynamicContractDataItemConfig({
  block,
  section,
  item,
  contract,
}) {
  const reduxData = useSelector((state) => state.dapp);

  const [showingContractsFuncsModal, setShowingContractsFuncsModal] =
    useState(false);

  useEffect(() => {
    setShowingContractsFuncsModal(false);
  }, [reduxData.dapp.config.windowClick]);

  return (
    <div className="dynamicContractDataItemConfig">
      <ColorPicker
        section={section}
        block={block}
        item={item}
        colorLabel="Color"
        color={item.color}
        changeColorFunc={changeDynamicContractDataItemColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
        switchValueLabel="2"
        switchValue={item.config.switchValueTwo}
      />

      <ConfigInput
        label={"Text"}
        type="text"
        value={item.text}
        onChangeFunc={changeDynamicContractDataItemText}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />

      <ConfigLabelWithComp
        label={"Function"}
        Comp={
          <ConfigOptionWithIcon
            option={item.selectedFunc.name}
            icon={
              <ChevronCircleState
                value={showingContractsFuncsModal}
                setValue={setShowingContractsFuncsModal}
              />
            }
          />
        }
      />

      <ConfigContractsAndFunctionsModal
        showingModal={showingContractsFuncsModal}
        contracts={reduxData.dapp.contracts}
        selectedContract={item.config.contractsView.selectedContract}
        options={item.config.contractsView.selectedContract.abi}
        isViewType={true}
        contractsViewActive={item.config.contractsView.active}
        funcOne={toggleItemConfigContractsViewActive}
        funcOneParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        modalOneTitle={"Contracts"}
        funcTwo={changeDynamicContractDataItemSelectedFunc}
        funcTwoParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        modalTwoTitle={"Functions"}
        toggleFunc={toggleItemConfigContractsViewActive}
        toggleFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          selectedContract: {
            abi: [],
            address: "",
          },
        }}
      />

      <ConfigRightItemRow
        Comp={TrashCan}
        func={deleteItem}
        funcParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        isReduxFunc={true}
        size={15}
      />
    </div>
  );
}
