import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  addItemIcon,
  changeIconUrl,
  changeItemIconsBackgroundColor,
  deleteItem,
} from "../../../../../../../../../redux/dapp";
import TrashCan from "../../../../../../../../icons/trashCan/TrashCan";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import ConfigInput from "../../../../configInput/ConfigInput";
import ConfigOptionPicker from "../../../../configOptionPicker/ConfigOptionPicker";
import ConfigRightItemRow from "../../../../configRightItemRow/ConfigRightItemRow";
import "./socialMediaIconsItemConfig.css";

export default function SocialMediaIconsItemConfig({ section, block, item }) {
  const icons = [
    { id: uuidv4(), name: "discord", icon: "FaDiscord", url: "" },
    { id: uuidv4(), name: "telegram", icon: "BsTelegram", url: "" },
    { id: uuidv4(), name: "twitter", icon: "BsTwitter", url: "" },
  ];

  const displayIcons = item.icons.map((icon) => {
    return (
      <div>
        {icon.name}
        <ConfigInput
          label="link"
          type="text"
          value={icon.url}
          onChangeFunc={changeIconUrl}
          onChangeFuncParams={{
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            iconId: icon.id,
            textValue: "",
          }}
          disabled={false}
        />
      </div>
    );
  });

  return (
    <div className="socialMediaIconsItemConfig">
      <ColorPicker
        colorLabel="Color"
        color={item.iconsColor}
        changeColorFunc={changeItemIconsBackgroundColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
      />
      {displayIcons}
      <ConfigOptionPicker
        buttonLabel="Add Icon"
        options={icons}
        buttonFunc={addItemIcon}
        buttonFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          itemOption: {},
        }}
      />
      <ConfigRightItemRow
        Comp={TrashCan}
        func={deleteItem}
        funcParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        isReduxFunc={true}
        size={15}
      />
    </div>
  );
}
