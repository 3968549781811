import React from "react";
import "./appDocsText.css";

export default function AppDocsText() {
  return (
    <div
      className="appDocsText"
      onClick={() => {
        window.open("https://documentation.dappos.io/");
      }}
    >
      Docs
    </div>
  );
}
