import React from "react";
import { useDispatch } from "react-redux";
import { FaTrash } from "react-icons/fa";
import "./trashCan.css";

export default function TrashCan({ func, funcParams, isReduxFunc, size }) {
  const dispatch = useDispatch();

  return (
    <FaTrash
      className="trashCan"
      size={size}
      onClick={() => {
        isReduxFunc ? dispatch(func(funcParams)) : func(funcParams);
      }}
    />
  );
}
