import React from "react";
import openUrl from "../../../helpers/openUrl";
import "./dapposBrandingContainer.css";

export default function DapposBrandingContainer() {
  return (
    <div
      className="dapposBrandingContainer"
      onClick={() => openUrl(["https://dappos.io/"])}
    >
      Made with dappos
    </div>
  );
}
