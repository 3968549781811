import React from "react";
import useFetchUserDapps from "../../../hooks/useFetchUserDapps";
import { useSelector } from "react-redux";
import DashboardDapp from "./dashboardDapp/DashboardDapp";
import "./dappsContainer.css";
import SpinnerComponent from "../../app/spinnerComponent/SpinnerComponent";

export default function DappsContainer() {
  const [loading, error] = useFetchUserDapps();

  const reduxData = useSelector((state) => state.dapps);

  const displayDapps = reduxData.dapps.map((dapp, idx) => {
    return <DashboardDapp key={idx} dapp={dapp} />;
  });

  return (
    <div
      className={
        "dappsContainer" + ` ${loading || reduxData.dapps.length === 0}`
      }
    >
      {loading ? (
        <SpinnerComponent size={50} color={"#2d6df8"} />
      ) : (
        <>
          {reduxData.dapps.length !== 0 ? (
            displayDapps
          ) : (
            <div className="noDapps-text">No dapps yet</div>
          )}
        </>
      )}
    </div>
  );
}
