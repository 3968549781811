const templateFactoryContract = {
  address: "0xAaa5f8604eDf4966a1Bba481376c90034A8AC3bA",
  abi: [
    {
      inputs: [
        {
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
        {
          internalType: "string",
          name: "name",
          type: "string",
        },
        {
          internalType: "string",
          name: "symbol",
          type: "string",
        },
        {
          internalType: "string",
          name: "initBaseURI",
          type: "string",
        },
        {
          internalType: "string",
          name: "initNotRevealedUri",
          type: "string",
        },
      ],
      name: "createNftContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "getLastDeployedContract",
      outputs: [
        {
          internalType: "contract NFT",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "lastDeployedContract",
      outputs: [
        {
          internalType: "contract NFT",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ],
};

export default templateFactoryContract;
