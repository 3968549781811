import React, { useState } from "react";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import SuccessMessage from "../../components/form/successMessage/SuccessMessage";
import resetPassword from "../../helpers/auth/resetPassword";
import useAuth from "../../hooks/useAuth";

export default function ForgotPassword() {
  const {
    signUpUser,
    loginUser,
    logoutUser,
    loading,
    error,
    resetPassword,
    responseMessage,
  } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
  });

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />

        <FormButton
          label="Reset Password"
          func={resetPassword}
          funcParams={[formData.email]}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={loading}
          disabled={!formData.email}
        />

        <ResponseMessage
          message={responseMessage}
          active={responseMessage !== ""}
          messageType={!error ? "success" : "error"}
        />
      </div>
    </div>
  );
}
