import React from "react";
import useStripeFirebaseSubscribe from "../../../../hooks/configSideBar/useStripeFirebaseSubscribe";
import useAuth from "../../../../hooks/useAuth";
import useRedirect from "../../../../hooks/useRedirect";
import DashboardButton from "../../../dashboard/dashboardButton/DashboardButton";
import "./planContainer.css";

export default function PlanContainer({ plan }) {
  const [initiateSubscription, initiateCancelSubscription, loading, error] =
    useStripeFirebaseSubscribe();
  const [redirect] = useRedirect();

  const { userLoggedIn } = useAuth();

  const displayPlanFeatures = plan.features.map((feature, idx) => {
    return (
      <div key={idx} className="plan-feature">
        ✔ {feature}
      </div>
    );
  });

  return (
    <div className="planContainer">
      <div className="plan-tier">{plan.tier}</div>
      <div className="plan-price-container">
        <div className="plan-price">{plan.pricing} </div>
        <div className="plan-price-month-text">/mo</div>
      </div>
      <div className="plan-features-container">{displayPlanFeatures}</div>
      <DashboardButton
        label={userLoggedIn ? "Upgrade" : "Start Free Trial"}
        func={userLoggedIn ? initiateSubscription : redirect}
        funcParams={
          userLoggedIn
            ? [
                plan.tier === "premium"
                  ? "price_1Ln11PIErH4arf0ol9wT240e"
                  : "price_1Ln127IErH4arf0oQqkEVhn1",
              ]
            : ["/SignUp"]
        }
        padding={"10px 10px"}
        loading={loading}
      />
    </div>
  );
}
