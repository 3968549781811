import React from "react";
import { useLocation } from "react-router-dom";
import DownloadDappContainer from "../../features/downloadDapp/components/downloadDappContainer/DownloadDappContainer";

import "./downloadDapp.css";

export default function DownloadDapp() {
  const location = useLocation();

  return (
    <div className="downloadDapp">
      <DownloadDappContainer dapp={location.state.dapp} />
    </div>
  );
}
