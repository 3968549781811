import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "../firebase/firebase";

const useDappSaved = () => {
  const reduxData = useSelector((state) => state.dapp);
  const [dappSaved, setDappSaved] = useState(false);

  const db = firebase.firestore();

  useEffect(() => {
    const fetch = async () => {
      try {
        const docRef = db
          .collection("activeFrontends")
          .doc(reduxData.dapp?.dappName);

        const result = await docRef.get();

        if (result.data().dappSaved > 0) {
          console.log("true");
          setDappSaved(true);
        }
      } catch (err) {
        console.log("err:", err);
      }
    };
    if (reduxData.dapp?.dappName) {
      fetch();
    }
  }, []);

  return [dappSaved];
};

export default useDappSaved;
