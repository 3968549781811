import { useState } from "react";
import firebase from "../../firebase/firebase";

const useStripeFirebaseSubscribe = () => {
  const db = firebase.firestore();

  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const initiateSubscription = async (planId) => {
    setLoading(true);
    console.log("planId:", planId);
    firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const docRef = await db
          .collection("users")
          .doc(currentUser.uid)
          .collection("checkout_sessions")
          .add({
            price: planId,
            success_url: window.location.origin,
            cancel_url: `${window.location.origin}/Pricing`,
            allow_promotion_codes: true,
          });

        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();

          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          } else {
            console.log("no url");
          }
        });
      } else {
        console.log("no user logged in");
      }
    });
  };

  const initiateCancelSubscription = async () => {
    setLoading(true);

    console.log("initiating");

    try {
      const functions = firebase.functions();

      const functionRef = firebase
        .functions()
        .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
      const result = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto",
        // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
      });
      window.location.assign(result.data.url);
    } catch (error) {
      console.error("Error opening Stripe customer portal:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return [initiateSubscription, initiateCancelSubscription, loading, error];
};

export default useStripeFirebaseSubscribe;
