import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButtonItemButtonBackgroundColor,
  changeButtonItemButtonLabel,
  changeButtonItemButtonTextColor,
  changeButtonItemSuccessMessage,
  changeItemSelectedFunc,
  deleteItem,
  toggleItemConfigContractsViewActive,
} from "../../../../../../../../../redux/dapp";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import ConfigInput from "../../../../configInput/ConfigInput";

import ChevronCircleState from "../../../../../../../../icons/chevronCircleState/ ChevronCircleState";
import TrashCan from "../../../../../../../../icons/trashCan/TrashCan";
import ConfigContractsAndFunctionsModal from "../../../../configContractsAndFunctionsModal/ConfigContractsAndFunctionsModal";
import ConfigItemFuncPrice from "../../../../configItemFuncPrice/ConfigItemFuncPrice";
import ConfigItemInputs from "../../../../configItemInputs/ConfigItemInputs";
import ConfigLabelWithComp from "../../../../ConfigLabelWithComp/ConfigLabelWithComp";
import ConfigOptionWithIcon from "../../../../configOptionWithIcon/ConfigOptionWithIcon";
import ConfigRightItemRow from "../../../../configRightItemRow/ConfigRightItemRow";

export default function ButtonItemConfig({ block, section, item }) {
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  const [showingContractsFuncsModal, setShowingContractsFuncsModal] =
    useState(false);

  useEffect(() => {
    setShowingContractsFuncsModal(false);
  }, [reduxData.dapp.config.windowClick]);

  return (
    <>
      <ColorPicker
        section={section}
        block={block}
        item={item}
        colorLabel="Background Color"
        color={item.buttonBackgroundColor}
        changeColorFunc={changeButtonItemButtonBackgroundColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
        switchValueLabel="1"
        switchValue={item.config.switchValueOne}
      />
      <ColorPicker
        section={section}
        block={block}
        item={item}
        colorLabel="Text Color"
        color={item.buttonTextColor}
        changeColorFunc={changeButtonItemButtonTextColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
        switchValueLabel="2"
        switchValue={item.config.switchValueTwo}
      />

      <ConfigLabelWithComp
        label={"Function"}
        Comp={
          <ConfigOptionWithIcon
            option={item.selectedFunc.name}
            icon={
              <ChevronCircleState
                value={showingContractsFuncsModal}
                setValue={setShowingContractsFuncsModal}
              />
            }
          />
        }
      />

      <ConfigContractsAndFunctionsModal
        showingModal={showingContractsFuncsModal}
        contracts={reduxData.dapp.contracts}
        selectedContract={item.config.contractsView.selectedContract}
        options={item.config.contractsView.selectedContract.abi}
        isViewType={false}
        contractsViewActive={item.config.contractsView.active}
        funcOne={toggleItemConfigContractsViewActive}
        funcOneParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        modalOneTitle={"Contracts"}
        funcTwo={changeItemSelectedFunc}
        funcTwoParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        modalTwoTitle={"Functions"}
        toggleFunc={toggleItemConfigContractsViewActive}
        toggleFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          selectedContract: {
            abi: [],
            address: "",
          },
        }}
      />

      <ConfigInput
        label={"button label"}
        type="text"
        value={item.buttonLabel}
        onChangeFunc={changeButtonItemButtonLabel}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />

      {/* <ConfigContractFuncs
        section={section}
        block={block}
        item={item}
        contract={reduxData.dapp.contracts[0]}
      /> */}

      {(item.selectedFunc.stateMutability === "payable") &
      (item.selectedFunc.price.isInputDependant.type !== "inputValue") ? (
        <ConfigItemFuncPrice section={section} block={block} item={item} />
      ) : null}

      <ConfigItemInputs section={section} block={block} item={item} />

      {item.selectedFunc.stateMutability !== "view" && (
        <ConfigInput
          label={"Success Message"}
          type="text"
          value={item.successMessage}
          onChangeFunc={changeButtonItemSuccessMessage}
          onChangeFuncParams={{
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            textValue: "",
          }}
          disabled={false}
        />
      )}

      <ConfigRightItemRow
        Comp={TrashCan}
        func={deleteItem}
        funcParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        isReduxFunc={true}
        size={15}
      />
    </>
  );
}
