import React from "react";
import AppGetHelp from "../../components/app/appGetHelp/AppGetHelp";
import AppHeader from "../../components/app/appHeader/AppHeader";
import DappsContainer from "../../components/dashboard/dappsContainer/DappsContainer";
import DashboardRow from "../../components/dashboard/dashboardRow/DashboardRow";
import { isMobile } from "react-device-detect";
import MobileViewMessage from "../../components/app/mobileViewMessage/MobileViewMessage";
import "./dashboard.css";
import useCheckTrial from "../../hooks/useCheckTrial";
import PreDappTutorialSteps from "../../components/app/tutorials/PreDappTutorialSteps";
import SettingsIcon from "../../components/app/settingsIcon/SettingsIcon";

export default function Dashboard() {
  const [trialFinished] = useCheckTrial();

  if (!isMobile) {
    return (
      <div className="dashboard">
        <AppHeader />
        <div className="dashboard-container">
          <DashboardRow />
          <DappsContainer />
          <div className="dashboard-container-settings-icon">
            <SettingsIcon />
          </div>

          {/* <AppGetHelp /> */}
        </div>
      </div>
    );
  } else {
    return <MobileViewMessage />;
  }
}
