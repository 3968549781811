import { useEffect, useState } from "react";
import { ethers } from "ethers";

const useMetamask = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [metamaskConnected, setMetamaskConnected] = useState(false);

  useEffect(() => {
    const initiateMetamaskConnection = async () => {
      let provider;
      if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }

      const metamaskAccounts = await provider.listAccounts();

      if (metamaskAccounts.length > 0) {
        setMetamaskConnected(true);
      } else {
        setMetamaskConnected(false);
      }
    };
    initiateMetamaskConnection();
  }, []);

  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          setMetamaskConnected(true);
        })
        .catch((err) => {
          console.log("Unable to connect with Metamask");
        });
    }
  };

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length === 0) {
        setMetamaskConnected(false);
      }

      if (accounts.length > 0) {
        setMetamaskConnected(true);
      }
    });
  }

  return [metamaskConnected, connectMetamask, loading, error];
};

export default useMetamask;
