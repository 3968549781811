import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import useRedirect from "./useRedirect";
import splitbee from "@splitbee/web";
import { v4 as uuidv4 } from "uuid";

const useAuth = (provider) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [responseMessage, setResponseMessage] = useState("");

  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [redirect] = useRedirect();

  const db = firebase.firestore();

  const recordTrialStarted = async (userId) => {
    const date = new Date();
    console.log("new date:", date);
    console.log("userId:", userId);
    const result = await db
      .collection("users")
      .doc(userId)
      .collection("account")
      .doc("freeTrial")
      .set({
        trialStartedOn: date,
      });

    return result;
  };

  const createTutorialDapp = async (userId) => {
    const dappId = uuidv4();
    const dappName = `TestDapp(${dappId})`;

    const frontendStructure = {
      connectButton: { textColor: "", backgroundColor: "" },
      sections: [
        {
          config: {
            configOpen: true,
            selectedBlockConfig: "",
            configActive: true,
          },
          id: "b1693577-332c-4d09-b437-bdde27d7aaa7",
          blocks: [
            {
              type: "block",
              id: "e077b30e-c8a3-4b20-9e66-c182ee5031d8",
              items: [
                {
                  id: "5907915c-45a9-4099-95ec-d6fb21432100",
                  text: "NFT Dapp",
                  font: { fontFamily: "default" },
                  textColor: "#0e1117",
                  type: "largeText",
                },
                {
                  name: "Button",
                  font: { fontFamily: "default" },
                  buttonTextColor: "#25272a",
                  id: "8796b470-ca26-4b6e-bdaf-a554b81eef83",
                  errorMessage: "Error :(",
                  config: {
                    switchValueOne: false,
                    switchValueTwo: false,
                    contractsView: {
                      selectedContract: {
                        abi: [
                          {
                            name: "Approval",
                            type: "event",
                            anonymous: false,
                            inputs: [
                              {
                                name: "owner",
                                type: "address",
                                indexed: true,
                                internalType: "address",
                              },
                              {
                                name: "approved",
                                indexed: true,
                                type: "address",
                                internalType: "address",
                              },
                              {
                                name: "tokenId",
                                type: "uint256",
                                internalType: "uint256",
                                indexed: true,
                              },
                            ],
                          },
                          {
                            anonymous: false,
                            name: "ApprovalForAll",
                            inputs: [
                              {
                                name: "owner",
                                type: "address",
                                internalType: "address",
                                indexed: true,
                              },
                              {
                                indexed: true,
                                internalType: "address",
                                name: "operator",
                                type: "address",
                              },
                              {
                                name: "approved",
                                type: "bool",
                                indexed: false,
                                internalType: "bool",
                              },
                            ],
                            type: "event",
                          },
                          {
                            inputs: [
                              {
                                type: "address",
                                name: "previousOwner",
                                internalType: "address",
                                indexed: true,
                              },
                              {
                                name: "newOwner",
                                internalType: "address",
                                indexed: true,
                                type: "address",
                              },
                            ],
                            anonymous: false,
                            type: "event",
                            name: "OwnershipTransferred",
                          },
                          {
                            type: "event",
                            inputs: [
                              {
                                type: "address",
                                indexed: true,
                                internalType: "address",
                                name: "from",
                              },
                              {
                                indexed: true,
                                internalType: "address",
                                type: "address",
                                name: "to",
                              },
                              {
                                indexed: true,
                                type: "uint256",
                                name: "tokenId",
                                internalType: "uint256",
                              },
                            ],
                            name: "Transfer",
                            anonymous: false,
                          },
                          {
                            inputs: [
                              {
                                internalType: "address",
                                type: "address",
                                name: "to",
                              },
                              {
                                name: "tokenId",
                                internalType: "uint256",
                                type: "uint256",
                              },
                            ],
                            stateMutability: "nonpayable",
                            type: "function",
                            name: "approve",
                            outputs: [],
                          },
                          {
                            outputs: [],
                            inputs: [
                              {
                                internalType: "uint256",
                                name: "quantity",
                                type: "uint256",
                              },
                            ],
                            name: "mint",
                            type: "function",
                            stateMutability: "nonpayable",
                          },
                          {
                            stateMutability: "nonpayable",
                            name: "renounceOwnership",
                            outputs: [],
                            type: "function",
                            inputs: [],
                          },
                          {
                            stateMutability: "nonpayable",
                            outputs: [],
                            name: "safeTransferFrom",
                            inputs: [
                              {
                                internalType: "address",
                                name: "from",
                                type: "address",
                              },
                              {
                                name: "to",
                                type: "address",
                                internalType: "address",
                              },
                              {
                                internalType: "uint256",
                                type: "uint256",
                                name: "tokenId",
                              },
                            ],
                            type: "function",
                          },
                          {
                            name: "safeTransferFrom",
                            stateMutability: "nonpayable",
                            inputs: [
                              {
                                name: "from",
                                internalType: "address",
                                type: "address",
                              },
                              {
                                internalType: "address",
                                type: "address",
                                name: "to",
                              },
                              {
                                internalType: "uint256",
                                name: "tokenId",
                                type: "uint256",
                              },
                              {
                                internalType: "bytes",
                                type: "bytes",
                                name: "data",
                              },
                            ],
                            type: "function",
                            outputs: [],
                          },
                          {
                            stateMutability: "nonpayable",
                            type: "function",
                            outputs: [],
                            name: "setApprovalForAll",
                            inputs: [
                              {
                                type: "address",
                                name: "operator",
                                internalType: "address",
                              },
                              {
                                type: "bool",
                                name: "approved",
                                internalType: "bool",
                              },
                            ],
                          },
                          {
                            inputs: [
                              {
                                type: "address",
                                name: "from",
                                internalType: "address",
                              },
                              {
                                name: "to",
                                type: "address",
                                internalType: "address",
                              },
                              {
                                type: "uint256",
                                internalType: "uint256",
                                name: "tokenId",
                              },
                            ],
                            outputs: [],
                            name: "transferFrom",
                            stateMutability: "nonpayable",
                            type: "function",
                          },
                          {
                            name: "transferOwnership",
                            stateMutability: "nonpayable",
                            outputs: [],
                            type: "function",
                            inputs: [
                              {
                                type: "address",
                                name: "newOwner",
                                internalType: "address",
                              },
                            ],
                          },
                          {
                            stateMutability: "nonpayable",
                            inputs: [],
                            type: "constructor",
                          },
                          {
                            type: "function",
                            outputs: [
                              {
                                internalType: "uint256",
                                name: "",
                                type: "uint256",
                              },
                            ],
                            name: "balanceOf",
                            stateMutability: "view",
                            inputs: [
                              {
                                name: "owner",
                                type: "address",
                                internalType: "address",
                              },
                            ],
                          },
                          {
                            outputs: [
                              {
                                name: "",
                                type: "address",
                                internalType: "address",
                              },
                            ],
                            stateMutability: "view",
                            name: "getApproved",
                            type: "function",
                            inputs: [
                              {
                                name: "tokenId",
                                type: "uint256",
                                internalType: "uint256",
                              },
                            ],
                          },
                          {
                            stateMutability: "view",
                            type: "function",
                            inputs: [
                              {
                                type: "address",
                                internalType: "address",
                                name: "owner",
                              },
                              {
                                name: "operator",
                                internalType: "address",
                                type: "address",
                              },
                            ],
                            name: "isApprovedForAll",
                            outputs: [
                              { type: "bool", internalType: "bool", name: "" },
                            ],
                          },
                          {
                            stateMutability: "view",
                            outputs: [
                              {
                                type: "string",
                                internalType: "string",
                                name: "",
                              },
                            ],
                            name: "name",
                            inputs: [],
                            type: "function",
                          },
                          {
                            type: "function",
                            name: "owner",
                            outputs: [
                              {
                                name: "",
                                type: "address",
                                internalType: "address",
                              },
                            ],
                            stateMutability: "view",
                            inputs: [],
                          },
                          {
                            type: "function",
                            inputs: [
                              {
                                name: "tokenId",
                                type: "uint256",
                                internalType: "uint256",
                              },
                            ],
                            name: "ownerOf",
                            outputs: [
                              {
                                name: "",
                                internalType: "address",
                                type: "address",
                              },
                            ],
                            stateMutability: "view",
                          },
                          {
                            stateMutability: "view",
                            outputs: [
                              { internalType: "bool", name: "", type: "bool" },
                            ],
                            inputs: [
                              {
                                name: "interfaceId",
                                internalType: "bytes4",
                                type: "bytes4",
                              },
                            ],
                            name: "supportsInterface",
                            type: "function",
                          },
                          {
                            stateMutability: "view",
                            name: "symbol",
                            type: "function",
                            outputs: [
                              {
                                internalType: "string",
                                name: "",
                                type: "string",
                              },
                            ],
                            inputs: [],
                          },
                          {
                            name: "tokenByIndex",
                            outputs: [
                              {
                                internalType: "uint256",
                                type: "uint256",
                                name: "",
                              },
                            ],
                            inputs: [
                              {
                                name: "index",
                                type: "uint256",
                                internalType: "uint256",
                              },
                            ],
                            type: "function",
                            stateMutability: "view",
                          },
                          {
                            name: "tokenOfOwnerByIndex",
                            stateMutability: "view",
                            inputs: [
                              {
                                name: "owner",
                                type: "address",
                                internalType: "address",
                              },
                              {
                                internalType: "uint256",
                                name: "index",
                                type: "uint256",
                              },
                            ],
                            type: "function",
                            outputs: [
                              {
                                type: "uint256",
                                internalType: "uint256",
                                name: "",
                              },
                            ],
                          },
                          {
                            inputs: [
                              {
                                type: "uint256",
                                internalType: "uint256",
                                name: "tokenId",
                              },
                            ],
                            name: "tokenURI",
                            stateMutability: "view",
                            outputs: [
                              {
                                name: "",
                                type: "string",
                                internalType: "string",
                              },
                            ],
                            type: "function",
                          },
                          {
                            name: "totalSupply",
                            inputs: [],
                            type: "function",
                            stateMutability: "view",
                            outputs: [
                              {
                                type: "uint256",
                                internalType: "uint256",
                                name: "",
                              },
                            ],
                          },
                        ],
                        name: "SimpleERC721",
                        address: "0xa750283D77d976247c4A36BE234DB367912a673d",
                      },
                      active: true,
                    },
                  },
                  type: "button",
                  successMessage: "Success :)",
                  buttonBackgroundColor: "#2d6df8",
                  buttonLabel: "Mint",
                  selectedFunc: {
                    type: "function",
                    price: {
                      priceValue: 0,
                      isInputDependant: { input: {}, type: "", value: false },
                    },
                    name: "mint",
                    inputs: [
                      {
                        internalType: "uint256",
                        value: "",
                        type: "uint256",
                        name: "quantity",
                        placeholder: "quantity",
                        id: "f94758d9-1c98-478c-b876-6923ca928232",
                      },
                    ],
                    outputs: [],
                    contract: {
                      name: "SimpleERC721",
                      abi: [
                        {
                          inputs: [
                            {
                              internalType: "address",
                              indexed: true,
                              name: "owner",
                              type: "address",
                            },
                            {
                              internalType: "address",
                              type: "address",
                              name: "approved",
                              indexed: true,
                            },
                            {
                              type: "uint256",
                              internalType: "uint256",
                              name: "tokenId",
                              indexed: true,
                            },
                          ],
                          name: "Approval",
                          type: "event",
                          anonymous: false,
                        },
                        {
                          inputs: [
                            {
                              internalType: "address",
                              type: "address",
                              indexed: true,
                              name: "owner",
                            },
                            {
                              indexed: true,
                              name: "operator",
                              internalType: "address",
                              type: "address",
                            },
                            {
                              internalType: "bool",
                              type: "bool",
                              name: "approved",
                              indexed: false,
                            },
                          ],
                          type: "event",
                          name: "ApprovalForAll",
                          anonymous: false,
                        },
                        {
                          name: "OwnershipTransferred",
                          inputs: [
                            {
                              indexed: true,
                              type: "address",
                              internalType: "address",
                              name: "previousOwner",
                            },
                            {
                              indexed: true,
                              name: "newOwner",
                              internalType: "address",
                              type: "address",
                            },
                          ],
                          anonymous: false,
                          type: "event",
                        },
                        {
                          name: "Transfer",
                          inputs: [
                            {
                              internalType: "address",
                              indexed: true,
                              name: "from",
                              type: "address",
                            },
                            {
                              type: "address",
                              name: "to",
                              indexed: true,
                              internalType: "address",
                            },
                            {
                              type: "uint256",
                              name: "tokenId",
                              internalType: "uint256",
                              indexed: true,
                            },
                          ],
                          type: "event",
                          anonymous: false,
                        },
                        {
                          name: "approve",
                          type: "function",
                          inputs: [
                            {
                              name: "to",
                              internalType: "address",
                              type: "address",
                            },
                            {
                              type: "uint256",
                              internalType: "uint256",
                              name: "tokenId",
                            },
                          ],
                          outputs: [],
                          stateMutability: "nonpayable",
                        },
                        {
                          inputs: [
                            {
                              name: "quantity",
                              internalType: "uint256",
                              type: "uint256",
                            },
                          ],
                          outputs: [],
                          type: "function",
                          name: "mint",
                          stateMutability: "nonpayable",
                        },
                        {
                          name: "renounceOwnership",
                          stateMutability: "nonpayable",
                          inputs: [],
                          outputs: [],
                          type: "function",
                        },
                        {
                          outputs: [],
                          name: "safeTransferFrom",
                          inputs: [
                            {
                              type: "address",
                              internalType: "address",
                              name: "from",
                            },
                            {
                              name: "to",
                              internalType: "address",
                              type: "address",
                            },
                            {
                              name: "tokenId",
                              internalType: "uint256",
                              type: "uint256",
                            },
                          ],
                          stateMutability: "nonpayable",
                          type: "function",
                        },
                        {
                          inputs: [
                            {
                              internalType: "address",
                              type: "address",
                              name: "from",
                            },
                            {
                              type: "address",
                              name: "to",
                              internalType: "address",
                            },
                            {
                              internalType: "uint256",
                              name: "tokenId",
                              type: "uint256",
                            },
                            {
                              name: "data",
                              type: "bytes",
                              internalType: "bytes",
                            },
                          ],
                          outputs: [],
                          name: "safeTransferFrom",
                          stateMutability: "nonpayable",
                          type: "function",
                        },
                        {
                          name: "setApprovalForAll",
                          type: "function",
                          inputs: [
                            {
                              type: "address",
                              name: "operator",
                              internalType: "address",
                            },
                            {
                              name: "approved",
                              internalType: "bool",
                              type: "bool",
                            },
                          ],
                          stateMutability: "nonpayable",
                          outputs: [],
                        },
                        {
                          stateMutability: "nonpayable",
                          type: "function",
                          outputs: [],
                          name: "transferFrom",
                          inputs: [
                            {
                              name: "from",
                              type: "address",
                              internalType: "address",
                            },
                            {
                              name: "to",
                              internalType: "address",
                              type: "address",
                            },
                            {
                              internalType: "uint256",
                              name: "tokenId",
                              type: "uint256",
                            },
                          ],
                        },
                        {
                          inputs: [
                            {
                              name: "newOwner",
                              type: "address",
                              internalType: "address",
                            },
                          ],
                          outputs: [],
                          stateMutability: "nonpayable",
                          name: "transferOwnership",
                          type: "function",
                        },
                        {
                          stateMutability: "nonpayable",
                          inputs: [],
                          type: "constructor",
                        },
                        {
                          type: "function",
                          inputs: [
                            {
                              name: "owner",
                              internalType: "address",
                              type: "address",
                            },
                          ],
                          name: "balanceOf",
                          outputs: [
                            {
                              internalType: "uint256",
                              name: "",
                              type: "uint256",
                            },
                          ],
                          stateMutability: "view",
                        },
                        {
                          name: "getApproved",
                          inputs: [
                            {
                              type: "uint256",
                              internalType: "uint256",
                              name: "tokenId",
                            },
                          ],
                          outputs: [
                            {
                              name: "",
                              type: "address",
                              internalType: "address",
                            },
                          ],
                          stateMutability: "view",
                          type: "function",
                        },
                        {
                          inputs: [
                            {
                              type: "address",
                              internalType: "address",
                              name: "owner",
                            },
                            {
                              name: "operator",
                              internalType: "address",
                              type: "address",
                            },
                          ],
                          type: "function",
                          name: "isApprovedForAll",
                          stateMutability: "view",
                          outputs: [
                            { name: "", type: "bool", internalType: "bool" },
                          ],
                        },
                        {
                          name: "name",
                          type: "function",
                          stateMutability: "view",
                          inputs: [],
                          outputs: [
                            {
                              type: "string",
                              internalType: "string",
                              name: "",
                            },
                          ],
                        },
                        {
                          stateMutability: "view",
                          outputs: [
                            {
                              name: "",
                              internalType: "address",
                              type: "address",
                            },
                          ],
                          type: "function",
                          inputs: [],
                          name: "owner",
                        },
                        {
                          stateMutability: "view",
                          type: "function",
                          outputs: [
                            {
                              type: "address",
                              internalType: "address",
                              name: "",
                            },
                          ],
                          name: "ownerOf",
                          inputs: [
                            {
                              internalType: "uint256",
                              type: "uint256",
                              name: "tokenId",
                            },
                          ],
                        },
                        {
                          inputs: [
                            {
                              internalType: "bytes4",
                              name: "interfaceId",
                              type: "bytes4",
                            },
                          ],
                          name: "supportsInterface",
                          type: "function",
                          stateMutability: "view",
                          outputs: [
                            { internalType: "bool", type: "bool", name: "" },
                          ],
                        },
                        {
                          type: "function",
                          outputs: [
                            {
                              name: "",
                              type: "string",
                              internalType: "string",
                            },
                          ],
                          stateMutability: "view",
                          name: "symbol",
                          inputs: [],
                        },
                        {
                          inputs: [
                            {
                              name: "index",
                              internalType: "uint256",
                              type: "uint256",
                            },
                          ],
                          name: "tokenByIndex",
                          stateMutability: "view",
                          outputs: [
                            {
                              internalType: "uint256",
                              name: "",
                              type: "uint256",
                            },
                          ],
                          type: "function",
                        },
                        {
                          outputs: [
                            {
                              name: "",
                              type: "uint256",
                              internalType: "uint256",
                            },
                          ],
                          name: "tokenOfOwnerByIndex",
                          type: "function",
                          stateMutability: "view",
                          inputs: [
                            {
                              type: "address",
                              name: "owner",
                              internalType: "address",
                            },
                            {
                              internalType: "uint256",
                              name: "index",
                              type: "uint256",
                            },
                          ],
                        },
                        {
                          outputs: [
                            {
                              type: "string",
                              internalType: "string",
                              name: "",
                            },
                          ],
                          inputs: [
                            {
                              type: "uint256",
                              name: "tokenId",
                              internalType: "uint256",
                            },
                          ],
                          name: "tokenURI",
                          stateMutability: "view",
                          type: "function",
                        },
                        {
                          inputs: [],
                          stateMutability: "view",
                          name: "totalSupply",
                          outputs: [
                            {
                              internalType: "uint256",
                              name: "",
                              type: "uint256",
                            },
                          ],
                          type: "function",
                        },
                      ],
                      address: "0xa750283D77d976247c4A36BE234DB367912a673d",
                    },
                    stateMutability: "nonpayable",
                  },
                },
                {
                  config: { switchValueTwo: false, switchValueOne: false },
                  type: "connectButton",
                  buttonBackgroundColor: "#2d6df8",
                  font: { fontFamily: "" },
                  buttonTextColor: "#25272a",
                  id: "a52b7b89-65fd-46ab-b08b-fee992887e1c",
                },
                {
                  text: "Connect to the Rinkeby test network to mint! ",
                  font: { fontFamily: "default" },
                  textColor: "#0e1117",
                  type: "smallText",
                  id: "391c8010-4b9c-48a8-ad0d-7b7ef75e718d",
                },
              ],
              backgroundColor: "#ffffff",
              config: {
                selectedConfigItem: {
                  font: { fontFamily: "default" },
                  id: "391c8010-4b9c-48a8-ad0d-7b7ef75e718d",
                  text: "Connect to the Rinkeby test network to mint! ",
                  type: "smallText",
                  textColor: "#0e1117",
                },
                configOpen: true,
                configActive: true,
              },
            },
          ],
          backgroundColor: "#1b2129",
        },
      ],
      font: {
        fontFamily: "Chakra Petch",
        config: { configOpen: true, configActive: true },
      },
    };

    const contractName = "SimpleERC721";
    const contractAddress = "0xa750283D77d976247c4A36BE234DB367912a673d";
    const contractAbi = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "approved",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "operator",
            type: "address",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "approved",
            type: "bool",
          },
        ],
        name: "ApprovalForAll",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "operator",
            type: "address",
          },
          {
            internalType: "bool",
            name: "approved",
            type: "bool",
          },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "getApproved",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "operator",
            type: "address",
          },
        ],
        name: "isApprovedForAll",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "ownerOf",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
          },
        ],
        name: "supportsInterface",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
        ],
        name: "tokenByIndex",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
        ],
        name: "tokenOfOwnerByIndex",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "tokenURI",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ];

    const dapp = {
      id: dappId,
      dappName: dappName,
      walletConnected: false,
      dappSaved: false,
      frontendStructure: frontendStructure,
      contracts: [
        {
          address: contractAddress,
          abi: contractAbi,
          name: contractName,
        },
      ],
      images: [],
      config: { windowClick: false },
    };
    const db = firebase.firestore();

    const saveInitialDappStructure = await db
      .collection("users")
      .doc(userId)
      .collection("dapps")
      .doc(dappId)
      .set(dapp);

    console.log("saveInitialDappStructure:", saveInitialDappStructure);

    const saveDappToActiveFrontends = await db
      .collection("activeFrontends")
      .doc(dappName)
      .set(dapp);
  };

  // const signUpUserOld = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     if (provider) {
  //       const response = await firebase.auth().signInWithPopup(provider);

  //       const recorded = await recordTrialStarted(response.user._delegate.uid);

  //       splitbee.track("Account Created");

  //       redirect("/");
  //       setLoading(false);
  //     } else {
  //       const response = await firebase
  //         .auth()
  //         .createUserWithEmailAndPassword(email, password);

  //       const recorded = await recordTrialStarted(response.user._delegate.uid);

  //       const tutorialDappCreated = await createTutorialDapp(
  //         response.user._delegate.uid
  //       );

  //       splitbee.track("Account created");

  //       redirect("/");
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     console.log("err:", err);

  //     setError(err.message);
  //     setLoading(false);
  //   }
  // };

  const signUpUser = async (email, password) => {
    setLoading(true);
    try {
      let response;
      if (provider) {
        response = await firebase.auth().signInWithPopup(provider);
      } else {
        response = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
      }

      // Record trial started
      const recorded = await recordTrialStarted(response.user._delegate.uid);

      // Create tutorial dapp
      const tutorialDappCreated = await createTutorialDapp(
        response.user._delegate.uid
      );

      // Check for unsaved dapp
      const unsavedDapp = localStorage.getItem("unsavedDapp");
      if (unsavedDapp) {
        const dapp = JSON.parse(unsavedDapp);
        // Save the dapp to Firestore using the uid of the newly created account
        await db
          .collection("users")
          .doc(response.user._delegate.uid)
          .collection("dapps")
          .doc(dapp.id)
          .set(dapp);
        // Delete the unsavedDapp from local storage
        localStorage.removeItem("unsavedDapp");
      }

      redirect("/");
      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // const loginUserOld = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     const response = await firebase
  //       .auth()
  //       .signInWithEmailAndPassword(email, password);

  //     redirect("/");
  //     setLoading(false);
  //   } catch (err) {
  //     console.log("err:", err);

  //     setError(err.message);
  //     setLoading(false);
  //   }
  // };

  const loginUser = async (email, password) => {
    setLoading(true);
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      // Check for unsaved dapp
      const unsavedDapp = localStorage.getItem("unsavedDapp");
      if (unsavedDapp) {
        const dapp = JSON.parse(unsavedDapp);
        // Save the dapp to Firestore using the uid of the logged-in account
        await db
          .collection("users")
          .doc(response.user._delegate.uid)
          .collection("dapps")
          .doc(dapp.id)
          .set(dapp);
        // Delete the unsavedDapp from local storage
        localStorage.removeItem("unsavedDapp");
      }

      redirect("/");
      setLoading(false);
    } catch (err) {
      console.log("err:", err);

      setError(err.message);
      setLoading(false);
    }
  };

  const logoutUser = async () => {
    setLoading(true);
    try {
      const response = await firebase.auth().signOut();

      redirect("/Login");
      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const resetPassword = async (email) => {
    console.log("running??");
    try {
      const response = await firebase.auth().sendPasswordResetEmail(email);
      const message = `A link to reset your password has been emailed to ${email}. Make sure to check your spam folder.`;
      setResponseMessage(message);
      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserLoggedIn(true);
      }
    });
  }, []);

  return {
    signUpUser,
    loginUser,
    logoutUser,
    loading,
    error,
    resetPassword,
    responseMessage,
    userLoggedIn,
  };
};

export default useAuth;
