import React from "react";
import useRedirect from "../../../hooks/useRedirect";

import "./redirectText.css";

export default function RedirectText({ text, redirectLink }) {
  const [redirect] = useRedirect();

  return (
    <div className="redirectText" onClick={() => redirect(redirectLink)}>
      {text}
    </div>
  );
}
