import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../../components/form/errorMessage/ErrorMessage";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import FormTextArea from "../../components/form/formTextArea/FormTextArea";
import useAddContract from "../../hooks/useAddContract";

export default function AddContract() {
  const location = useLocation();

  const [formData, setFormData] = useState({
    dappId: null,
    contractAddress: "",
    contractAbi: "",
    contractName: "",
  });

  const [addContract, loading, error] = useAddContract(
    formData.dappId,
    formData.contractAddress,
    formData.contractAbi,
    formData.contractName
  );

  useEffect(() => {
    if (location.state) {
      setFormData({
        ...formData,
        dappId: location.state.dapp.id,
      });
    }
  }, []);

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          placeHolder={"Contract address"}
          value={formData.contractAddress}
          setStateValue={(contractAddress) =>
            setFormData({ ...formData, contractAddress: contractAddress })
          }
        />

        <FormTextArea
          placeHolder={"Contract abi"}
          value={formData.contractAbi}
          setStateValue={(contractAbi) =>
            setFormData({ ...formData, contractAbi: contractAbi })
          }
        />

        <FormInput
          placeHolder={"Contract Name"}
          value={formData.contractName}
          setStateValue={(contractName) =>
            setFormData({ ...formData, contractName: contractName })
          }
        />

        <FormButton
          label={"Add contract"}
          func={addContract}
          funcParams={[
            formData.dappId,
            formData.contractName.replace(/\s/g, ""),
            formData.contractAddress,
            formData.contractAbi,
          ]}
          loading={loading}
        />

        {error && <ErrorMessage message={error} />}
      </div>
    </div>
  );
}
