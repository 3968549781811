import { useNavigate } from "react-router-dom";

const useRedirect = () => {
  const navigate = useNavigate();

  const redirect = (path, state) => {
    navigate(path, { state: state });
  };

  return [redirect];
};

export default useRedirect;
