import React from "react";
import { useDispatch } from "react-redux";
import { toggleItemConfigContractsViewActive } from "../../../../../../redux/dapp";
import { v4 as uuidv4 } from "uuid";
import BackChevron from "../../../../../icons/backChevron/BackChevron";
import "./configContractsAndFunctionsModal.css";
import splitbee from "@splitbee/web";

export default function ConfigContractsAndFunctionsModal({
  showingModal,
  contracts,
  selectedContract,
  options,
  isViewType,
  contractsViewActive,
  funcOne,
  funcOneParams,
  modalOneTitle,
  funcTwo,
  funcTwoParams,
  modalTwoTitle,
  toggleFunc,
  toggleFuncParams,
}) {
  const dispatch = useDispatch();

  const displayContracts = contracts.map((contract, idx) => {
    return (
      <button
        key={idx}
        className="modal-button-one"
        onClick={() => {
          dispatch(
            funcOne({
              ...funcOneParams,
              selectedContract: contract,
            })
          );
        }}
      >
        {contract.name}
      </button>
    );
  });

  const displayOptions = options.map((option, idx) => {
    const inputsArr = [];

    let optionsConditions = isViewType
      ? (option.type === "function") &
        (option.stateMutability === "view") &
        (option.inputs < 1)
      : option.type === "function";

    // if (!isViewType) {
    //   funcTwoParams = {
    //     ...funcTwoParams,
    //     price: {
    //       isInputDependant: {
    //         value: false,
    //         type: "",
    //         input: {},
    //       },
    //       priceValue: 0,
    //     },
    //   };
    // }

    if (optionsConditions) {
      option.inputs.forEach((input) => {
        inputsArr.push({
          ...input,
          id: uuidv4(),
          placeholder: input.name,
          value: "",
        });
      });

      return (
        <button
          key={idx}
          className="modal-button-two"
          onClick={() => {
            dispatch(
              funcTwo({
                ...funcTwoParams,
                selectedFunc: {
                  ...option,
                  inputs: inputsArr,
                  contract: selectedContract,
                  price: {
                    isInputDependant: {
                      value: false,
                      type: "",
                      input: {},
                    },
                    priceValue: 0,
                  },
                },
              })
            );

            splitbee.track("Function assigned to button");
          }}
        >
          {option.name}
        </button>
      );
    }
  });

  if (showingModal & (contracts.length === 1)) {
    return <div className="modal-container">{displayOptions}</div>;
  }

  if (showingModal) {
    return (
      <div className="modal-container">
        {contractsViewActive ? (
          <>
            {modalOneTitle}
            {displayContracts}
          </>
        ) : (
          <>
            <div className="modal-title">
              <BackChevron
                configActiveToggleAction={toggleFunc}
                configActiveToggleActionParams={toggleFuncParams}
              />
              {modalTwoTitle}
            </div>
            {displayOptions}
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
}
