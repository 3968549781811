import React from "react";
import useDeleteDappContract from "../../../../../../hooks/useDeleteDappContract";
import SpinnerComponent from "../../../../../app/spinnerComponent/SpinnerComponent";
import TrashCan from "../../../../../icons/trashCan/TrashCan";
import "./dashboardDappContract.css";

export default function DashboardDappContract({ dapp, contract }) {
  const [deleteConfirmation, loading, error] = useDeleteDappContract(
    dapp,
    contract
  );

  return (
    <div className="dashboardDappContract">
      <div>{contract.name}</div>
      <div className="dashboardDappContract-row">
        {/* <div className="dashboardDappContract-contractAddress">
          {contract.address}
        </div> */}
        <div>{contract.address.substring(0, 15)}...</div>
        <div className="dashboardDappContract-row-rightItem">
          {loading ? (
            <SpinnerComponent size={25} color={"#1b2129"} />
          ) : (
            <TrashCan func={deleteConfirmation} funcParams={[]} />
          )}
        </div>
      </div>
    </div>
  );
}
