import React from "react";
import { useSelector } from "react-redux";
import useConfigImage from "../../../../../../hooks/configSideBar/useConfigImage";
import SpinnerComponent from "../../../../../app/spinnerComponent/SpinnerComponent";
import FileInput from "../fileInput/FileInput";
import "./imageUpload.css";
import ImageUploadCurrentImage from "./imageUploadCurrentImage/ImageUploadCurrentImage";

export default function ImageUpload({ section, block, item }) {
  const reduxData = useSelector((state) => state.dapp);

  const [uploadImage, loading] = useConfigImage({
    sectionId: section.id,
    blockId: block.id,
    itemId: item.id,
  });

  return (
    <div>
      <FileInput
        onChangeFunc={uploadImage}
        loading={loading}
        hasImage={item.image === "" ? "noImage" : "hasImage"}
      />

      {loading ? (
        <SpinnerComponent size={25} color={"#1b2129"} />
      ) : (
        <ImageUploadCurrentImage image={item.image} />
      )}
    </div>
  );
}
