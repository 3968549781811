import splitbee from "@splitbee/web";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase/firebase";
import { toggleDappSaved } from "../redux/dapp";

const useSaveDapp = (dapp) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveDapp = async () => {
    setLoading(true);
    const db = firebase.firestore();

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const saveUserDapp = await db
            .collection("users")
            .doc(user.uid)
            .collection("dapps")
            .doc(dapp.id)
            .set({
              ...dapp,
              dappSaved: true,
            });

          const saveActiveDapp = await db
            .collection("activeFrontends")
            .doc(dapp.dappName)
            .set(
              {
                ...dapp,
                dappSaved: firebase.firestore.FieldValue.increment(1),
              },
              { merge: true }
            );

          dispatch(toggleDappSaved());

          splitbee.track("Dapp saved");

          setLoading(false);
        } catch (err) {
          setLoading(false);

          alert(
            "There was a problem saving the dapp. Please try again later or contact us"
          );
          console.log("err:", err);
        }
      } else {
        localStorage.setItem("unsavedDapp", JSON.stringify(dapp));
        navigate("/SignUp?remixRedirected=true");
      }
    });
  };

  return [saveDapp, loading, error];
};

export default useSaveDapp;
