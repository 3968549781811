import React from "react";
import "./configOptionWithIcon.css";

export default function ConfigOptionWithIcon({ option, icon }) {
  return (
    <div className="configOptionWithIcon">
      <div>{option}</div>
      <div className="configOptionWithIcon-icon">{icon}</div>
    </div>
  );
}
