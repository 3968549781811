import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MobileViewMessage from "../../../components/app/mobileViewMessage/MobileViewMessage";
import DbContainer from "../../../components/dappBuilder/dbContainer/DbContainer";
import DbTopBar from "../../../components/dappBuilder/dbTopBar/DbTopBar";
import firebase from "../../../firebase/firebase";
import { populateDapp, toggleWindowClick } from "../../../redux/dapp";
import "./dappBuilder.css";

export default function DappBuilder() {
  const db = firebase.firestore();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  const queryParams = new URLSearchParams(location.search);
  const uniqueId = queryParams.get("uniqueId");
  const dappId = queryParams.get("dappId");
  const navigate = useNavigate();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      let docRef;
      if (user) {
        // If location.state.dapp.id exists, use it
        if (location.state && location.state.dapp && location.state.dapp.id) {
          docRef = db
            .collection("users")
            .doc(user.uid)
            .collection("dapps")
            .doc(location.state.dapp.id);
        }
        // If dappId exists, use it
        else if (dappId) {
          docRef = db
            .collection("remixUsers")
            .doc(uniqueId)
            .collection("dapps")
            .doc(dappId);
        }
      }
      // If the user is not logged in but dappId exists, use it
      else if (dappId) {
        docRef = db
          .collection("remixUsers")
          .doc(uniqueId)
          .collection("dapps")
          .doc(dappId);
      }

      if (docRef) {
        const result = await docRef.get();
        console.log("populating dapp");
        dispatch(populateDapp(result.data()));
      } else {
        navigate("/Login");
      }
    });
  }, []);

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(async (user) => {
  //     if (user) {
  //       const docRef = db
  //         .collection("users")
  //         .doc(user.uid)
  //         .collection("dapps")
  //         .doc(location.state.dapp.id);

  //       const result = await docRef.get();

  //       console.log("populating dapp");

  //       dispatch(populateDapp(result.data()));
  //     }
  //   });
  // }, []);

  const initiateWindowClick = (e) => {
    // console.log(e.target.className);
    if (
      e.target.className === "configContent" ||
      e.target.className === "modal-container" ||
      e.target.className === "modal-title" ||
      e.target.className === "modal-button-one" ||
      //
      e.target.className === "colorPicker-squareButton" ||
      e.target.className === "configStandardButton" ||
      e.target.className === "custom-file-input" ||
      e.target.className === "config-fontPickerButton" ||
      e.target.className === "assignedFunction-box" ||
      e.target.className === "config-modal-button" ||
      e.target.className === "assignedFunction-optionModalContainer-title" ||
      e.target.className.baseVal === "chevronCircle" ||
      e.target.className.baseVal === "backChevron" ||
      e.target.className.baseVal === "" ||
      e.target.className === "assignedFunction-optionModalContainer-title"
    ) {
      return;
    }

    dispatch(toggleWindowClick());
  };

  if (!isMobile) {
    return (
      <div className="dappBuilder" onClick={(e) => initiateWindowClick(e)}>
        <DbTopBar />
        <DbContainer />
      </div>
    );
  } else {
    return <MobileViewMessage />;
  }
}
