import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeBlockBackgroundColor,
  deleteBlock,
  setReorderedBlockItems,
  toggleBlockConfigActive,
} from "../../../../../../../redux/dapp";
import TrashCan from "../../../../../../icons/trashCan/TrashCan";
import ColorPicker from "../../colorPicker/ColorPicker";
import ConfigBubble from "../../configBubble/ConfigBubble";
import ConfigRightItemRow from "../../configRightItemRow/ConfigRightItemRow";
import ConfigStandardButton from "../../configStandardButton/ConfigStandardButton";
import DragNdrop from "../../dragNdrop/DragNdrop";
import "./blockConfig.css";
import BlockItemPicker from "./blockItemPicker/BlockItemPicker";

export default function BlockConfig({ section, block }) {
  const [showBlockItemPicker, setShowBlockItemPicker] = useState(false);

  const dispatch = useDispatch();

  const displayItemConfigs = block.items.map((item, idx) => {
    return (
      <ConfigBubble
        key={idx}
        name={item.type}
        actionFunc={toggleBlockConfigActive}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          item: item,
        }}
      />
    );
  });

  return (
    <>
      <ColorPicker
        colorLabel="Background Color"
        color={block.backgroundColor}
        changeColorFunc={changeBlockBackgroundColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          color: "",
        }}
      />
      {showBlockItemPicker ? (
        <BlockItemPicker
          section={section}
          block={block}
          closeItemPicker={() => setShowBlockItemPicker(false)}
        />
      ) : (
        <DragNdrop
          list={block.items}
          onDragFunc={setReorderedBlockItems}
          onDragFuncParams={{
            sectionId: section.id,
            blockId: block.id,
          }}
          configBubbleFunc={toggleBlockConfigActive}
          configBubbleFuncParams={{
            sectionId: section.id,
            blockId: block.id,
          }}
        />
      )}

      <ConfigStandardButton
        label={showBlockItemPicker ? "hide" : "+ Add Item"}
        func={setShowBlockItemPicker}
        funcParams={!showBlockItemPicker}
        padding={"12px 20px"}
      />

      <ConfigRightItemRow
        Comp={TrashCan}
        func={deleteBlock}
        funcParams={{ sectionId: section.id, blockId: block.id }}
        isReduxFunc={true}
        size={15}
      />
    </>
  );
}
