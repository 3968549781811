import React from "react";
import { IoChevronDownCircle, IoChevronUpCircle } from "react-icons/io5";
import "./chevronCircleState.css";

export default function ChevronCircleState({ value, setValue }) {
  return (
    <>
      {value ? (
        <IoChevronDownCircle
          className="chevronCircle"
          onClick={() => setValue(false)}
        />
      ) : (
        <IoChevronUpCircle
          className="chevronCircle"
          onClick={() => setValue(true)}
        />
      )}
    </>
  );
}
