import React, { useState } from "react";
import AlternativeAuthContainer from "../../components/alternativeAuthContainer/AlternativeAuthContainer";

import ErrorMessage from "../../components/form/errorMessage/ErrorMessage";
import "../../components/form/form.css";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import RedirectText from "../../components/form/redirectText/RedirectText";
import useAuth from "../../hooks/useAuth";

export default function Login() {
  const { signUpUser, loginUser, logoutUser, loading, error } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          type="email"
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />
        <FormInput
          type="password"
          placeHolder={"Password"}
          value={formData.passwordOne}
          setStateValue={(password) =>
            setFormData({ ...formData, password: password })
          }
        />
        <FormButton
          label="login"
          func={loginUser}
          funcParams={[formData.email, formData.password]}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={loading}
          disabled={loading}
        />

        <RedirectText
          text={"Forgot password?"}
          redirectLink={"/ForgotPassword"}
        />

        {error && <ErrorMessage message={error} />}

        <AlternativeAuthContainer inLogin={true} />
      </div>
    </div>
  );
}
