import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AppHeader from "../../components/app/appHeader/AppHeader";
import HelpQuestionMark from "../../components/app/helpQuestionMark/HelpQuestionMark";
import TemplateOptionsContainer from "../../components/dappCreation/templateOptionsContainer/TemplateOptionsContainer";
import ErrorMessage from "../../components/form/errorMessage/ErrorMessage";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import FormTextArea from "../../components/form/formTextArea/FormTextArea";
import openUrl from "../../helpers/openUrl";
import useCreateDapp from "../../hooks/useCreateDapp";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import nftErc721Template from "../../templates/nftErc721";
import customTemplate from "../../templates/custom";
import useMetamask from "../../hooks/useMetamask";
import "./createDapp.css";

export default function CreateDapp() {
  const initialFrontendStructure = {
    sections: [
      {
        id: uuidv4(),
        backgroundColor: "#1b2129",
        config: {
          configOpen: true,
          configActive: true,
          selectedBlockConfig: "",
        },
        blocks: [],
      },
    ],
    font: {
      fontFamily: "Chakra Petch",
      config: { configOpen: true, configActive: true },
    },
    connectButton: { backgroundColor: "", textColor: "" },
  };

  const location = useLocation();

  const [formData, setFormData] = useState({
    contractAddress: "",
    contractAbi: [],
    contractName: "",
    dappName: "",
  });

  const [selectedTemplate, setSelectedTemplate] = useState(customTemplate);

  const [createDapp, deployAndCreate, loading, error] = useCreateDapp(
    selectedTemplate?.frontendStructure,
    formData.dappName,
    formData.contractAddress,
    formData.contractAbi,
    formData.contractName
  );

  const options = [
    // "Sepolia (Eth test network)",
    "Mumbai (Polygon test network)",
  ];
  const defaultOption = options[0];

  const [metamaskConnected, connectMetamask, metamaskLoading, metamaskError] =
    useMetamask();

  return (
    <>
      <AppHeader />
      <div className="form-container">
        <div className="form">
          <>
            <FormInput
              placeHolder={"Contract address"}
              value={formData.contractAddress}
              setStateValue={(contractAddress) =>
                setFormData({ ...formData, contractAddress: contractAddress })
              }
            />

            <FormTextArea
              placeHolder={"Contract abi"}
              value={formData.contractAbi}
              setStateValue={(contractAbi) =>
                setFormData({ ...formData, contractAbi: contractAbi })
              }
            />

            <FormInput
              placeHolder={"Contract Name"}
              value={formData.contractName}
              setStateValue={(contractName) =>
                setFormData({ ...formData, contractName: contractName })
              }
            />
          </>

          <FormInput
            placeHolder={"Dapp name"}
            value={formData.dappName}
            setStateValue={(dappName) =>
              setFormData({
                ...formData,
                dappName: dappName.replace(/\s/g, ""),
              })
            }
          />
          <FormButton
            label="Create Dapp"
            func={createDapp}
            funcParams={[selectedTemplate]}
            loading={loading}
          />

          {error && <ErrorMessage message={error} />}
        </div>
      </div>
    </>
  );
}
