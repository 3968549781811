import React from "react";
import "./accountSettings.css";
import AppHeader from "../../components/app/appHeader/AppHeader";
import useCheckUserPlan from "../../hooks/useCheckUserPlan";
import useStripeFirebaseSubscribe from "../../hooks/configSideBar/useStripeFirebaseSubscribe";
import SpinnerComponent from "../../components/app/spinnerComponent/SpinnerComponent";

export default function AccountSettings() {
  const [plan] = useCheckUserPlan();

  const [initiateSubscription, initiateCancelSubscription, loading, error] =
    useStripeFirebaseSubscribe();

  const handleCancelClick = () => {
    if (window.confirm("Are you sure you want to cancel your subscriptions?")) {
      initiateCancelSubscription();
    }
  };

  return (
    <div className="account-settings">
      <AppHeader />
      <div className="account-settings-container">
        <div className="account-settings-row">Settings</div>
        <div className="account-settings-options-container">
          <div className="account-settings-option">
            <div>Plan: {plan}</div>
            <button
              className="account-settings-cancel-button"
              onClick={handleCancelClick}
            >
              {loading ? (
                <SpinnerComponent size={25} color={"#ffffff"} />
              ) : (
                "Cancel"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
