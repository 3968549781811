import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addBlockItem } from "../../../../../../../../redux/dapp";
import "./blockItemPicker.css";

export default function BlockItemPicker({ section, block, closeItemPicker }) {
  const reduxData = useSelector((state) => state.dapp);

  const itemList = [
    "Button",
    "Connect Button",
    "Large Text",
    "Small Text",
    "Image",
    "Dynamic Contract Data",
    "Social Media Icons",
  ];

  const dispatch = useDispatch();

  const initiateAddBlockItem = (item) => {
    var selectedItem;
    if (item === "Button") {
      selectedItem = {
        id: uuidv4(),
        type: "button",
        name: "Button",
        buttonLabel: "Button",
        buttonBackgroundColor: "#2d6df8",
        buttonTextColor: "#25272a",
        selectedFunc: {
          name: "Select Function",
          stateMutability: "nonpayable",
          type: "function",
          price: {
            isInputDependant: {
              value: false,
              type: "",
              input: {},
            },
            priceValue: 0,
          },
          inputs: [],
          outputs: [],
          contract: reduxData.dapp.contracts[0],
        },

        successMessage: "Success :)",
        errorMessage: "Error :(",
        config: {
          switchValueOne: false,
          switchValueTwo: false,
          contractsView: {
            active: true,
            selectedContract: reduxData.dapp.contracts[0],
          },
        },
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Connect Button") {
      selectedItem = {
        id: uuidv4(),
        type: "connectButton",
        buttonBackgroundColor: "#2d6df8",
        buttonTextColor: "#25272a",
        config: {
          switchValueOne: false,
          switchValueTwo: false,
        },
        font: {
          fontFamily: "",
        },
      };
    } else if (item === "Large Text") {
      selectedItem = {
        id: uuidv4(),
        type: "largeText",
        text: "Large Text",
        textColor: "#0e1117",
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Small Text") {
      selectedItem = {
        id: uuidv4(),
        type: "smallText",
        text: "Small Text",
        textColor: "#0e1117",
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Image") {
      selectedItem = { id: uuidv4(), type: "image", image: "" };
    } else if (item === "Dynamic Contract Data") {
      selectedItem = {
        id: uuidv4(),
        type: "dynamicContractData",
        selectedFunc: { name: "Select Function" },
        text: "Fetch data from your Contract",
        color: "#0e1117",
        config: {
          configSwitch: false,
          switchValueOne: false,
          switchValueTwo: false,
          contractsView: {
            active: true,
            selectedContract: reduxData.dapp.contracts[0],
          },
        },
        font: {
          fontFamily: "default",
        },
      };
      // selectedItem = {
      //   id: uuidv4(),
      //   type: "dynamicContractData",
      //   dcdText: "Display data fetch by your contract",
      //   dcdFuncInstances: [],
      //   config: { configSwitch: false },
      //   font: {
      //     fontFamily: "default",
      //   },
      // };
    } else if (item === "Social Media Icons") {
      selectedItem = {
        id: uuidv4(),
        type: "socialMediaIcons",
        icons: [],
        fontFamily: "default",
      };
    }
    dispatch(
      addBlockItem({
        sectionId: section.id,
        blockId: block.id,
        item: selectedItem,
      })
    );
    closeItemPicker();
  };

  const displayItemList = itemList.map((item, idx) => {
    return (
      <button
        key={idx}
        className="blockItemPicker-item"
        onClick={() => initiateAddBlockItem(item)}
      >
        {item}
      </button>
    );
  });

  return <div className="blockItemPicker">{displayItemList}</div>;
}
