import "./configCheckMarkWithSentence.css";
import React from "react";
import CheckMark from "../../../../../icons/checkMark/CheckMark";

export default function ConfigCheckMarkWithSentence({
  boolValue,
  func,
  funcParams,
  sentence,
  input,
}) {
  return (
    <div className="configCheckMarkWithSentence">
      <CheckMark boolValue={boolValue} func={func} funcParams={funcParams} />
      <div className="configCheckMarkWithSentence-text">{sentence}</div>
    </div>
  );
}
