import React from "react";
import DashboardButton from "../dashboardButton/DashboardButton";
import useRedirect from "../../../hooks/useRedirect";
import "./dashboardRow.css";
import SpinnerComponent from "../../app/spinnerComponent/SpinnerComponent";
import useCheckUserPlan from "../../../hooks/useCheckUserPlan";
import { useSelector } from "react-redux";

export default function DashboardRow() {
  const [plan, loading, error] = useCheckUserPlan();
  const [redirect] = useRedirect();

  const reduxData = useSelector((state) => state.dapps);

  return (
    <div className="dashboardRow">
      <div>Dapps</div>
      <div className="dashboardRow-rightItem">
        <DashboardButton
          label={"+ New Dapp"}
          func={redirect}
          funcParams={["/createDapp"]}
          // funcParams={
          //   reduxData.dapps.length < 1 || plan === "premium"
          //     ? ["/createDapp"]
          //     : ["/Pricing"]
          // }
          padding={"12px 20px"}
          loading={loading}
        />
      </div>
    </div>
  );
}
