import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import dapposInterfaceDappReducer from "./dapposInterface/redux/dapposInterfaceDapp";
import "./index.css";
import dappReducer from "./redux/dapp";
import dappsReducer from "./redux/dapps";

const store = configureStore({
  reducer: {
    dapp: dappReducer,
    dapps: dappsReducer,
    dapposInterfaceDapp: dapposInterfaceDappReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
