import React from "react";
import SpinnerComponent from "../spinnerComponent/SpinnerComponent";
import "./appButton.css";

export default function AppButton({
  label,
  func,
  funcParams,
  padding,
  loading,
}) {
  return (
    <button
      disabled={loading}
      className="dashboardButton"
      onClick={() => func(...funcParams)}
      style={{ padding: padding }}
    >
      {loading ? <SpinnerComponent size={25} color={"#1b2129"} /> : label}
    </button>
  );
}
