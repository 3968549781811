import React from "react";
import { IoChevronBack } from "react-icons/io5";
import { useDispatch } from "react-redux";
import "./backChevron.css";

export default function BackChevron({
  configActiveToggleAction,
  configActiveToggleActionParams,
  size,
}) {
  const dispatch = useDispatch();

  return (
    <IoChevronBack
      className="backChevron"
      size={size}
      onClick={() => {
        dispatch(configActiveToggleAction(configActiveToggleActionParams));
      }}
    />
  );
}
