import React from "react";
import "./pricingContainer.css";
import subscriptionPlans from "../../../data/subscriptionPlans";
import PlanContainer from "./planContainer/PlanContainer";

export default function PricingContainer() {
  const displayPlans = subscriptionPlans.map((plan, idx) => {
    return <PlanContainer key={idx} plan={plan} />;
  });

  return (
    <div className="pricingContainer">
      <div className="pricing-heading">The fastest way to build a Dapp</div>
      <div className="pricing-sub-heading">
        7 days free trial, no credit card required!
      </div>
      <div className="pricing-sub-heading-2">
        Unlike most no-code dapp builders out there Dappos doesn't take a cut
        from your smart contract transactions!
      </div>
      <div className="plans-container">{displayPlans}</div>
    </div>
  );
}
