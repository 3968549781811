import React from "react";
import ChevronCircle from "../../../../../icons/chevronCircle/ChevronCircle";
import { toggleSectionConfigActive } from "../../../../../../redux/dapp";
import { useSelector, useDispatch } from "react-redux";
import BackChevron from "../../../../../icons/backChevron/BackChevron";
import "./configHeader.css";

export default function ConfigHeader({
  section,
  configName,
  configOpenToggleAction,
  configOpenToggleActionParams,
  configActiveToggleAction,
  configActiveToggleActionParams,
  configOpen,
  configActive,
}) {
  const reduxData = useSelector((state) => state.dapp);
  return (
    <div className="configHeader">
      {!configActive && (
        <BackChevron
          configActiveToggleAction={configActiveToggleAction}
          configActiveToggleActionParams={configActiveToggleActionParams}
          size={30}
        />
      )}
      <div className="configHeader-title">{configName}</div>

      <div className="configHeader-rightItem">
        <ChevronCircle
          section={section}
          configOpenToggleAction={configOpenToggleAction}
          configOpenToggleActionParams={configOpenToggleActionParams}
          configOpen={configOpen}
        />
      </div>
    </div>
  );
}
