import React, { useEffect } from "react";
import DbCanvas from "../../../dapposInterface/components/dbCanvas/DbCanvas";
import "./dbContainer.css";
import DbSidebar from "./dbSidebar/DbSidebar";
import { useSelector } from "react-redux";
import {
  changeItemDynamicContractDataValueReturnValue,
  changeItemSelectedFuncInputValue,
  changeItemSelectedFuncPrice,
  clearItemSelectedFuncInputValues,
  toggleLoadingDapp,
  toggleWalletConnected,
  updateDynamicContractDataItems,
} from "../../../redux/dapp";
import { useDispatch } from "react-redux";
import DownloadDappBubble from "../../app/downloadDappBubble/DownloadDappBubble";
import useCheckUserPlan from "../../../hooks/useCheckUserPlan";
import DashboardButton from "../../dashboard/dashboardButton/DashboardButton";

export default function DbContainer() {
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  const interfaceFunctions = {
    toggleWalletConnected: toggleWalletConnected,
    changeItemSelectedFuncInputValue: changeItemSelectedFuncInputValue,
    changeItemSelectedFuncPrice: changeItemSelectedFuncPrice,
    clearItemSelectedFuncInputValues: clearItemSelectedFuncInputValues,
    updateDynamicContractDataItems: updateDynamicContractDataItems,
    changeItemDynamicContractDataValueReturnValue:
      changeItemDynamicContractDataValueReturnValue,
  };

  useEffect(() => {
    return () => {
      dispatch(toggleLoadingDapp());
    };
  }, []);

  const [plan, loading, error] = useCheckUserPlan();

  return (
    <div className="dbContainer">
      <DbSidebar />
      <DbCanvas
        inBuilder={true}
        dapp={reduxData.dapp}
        loadingDapp={reduxData.loadingDapp}
        reduxSlice="dapp"
        interfaceFunctions={interfaceFunctions}
      />

      {reduxData?.dapp?.dappSaved && (
        <DownloadDappBubble dapp={reduxData.dapp} />
      )}
    </div>
  );
}
