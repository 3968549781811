import { useState } from "react";
import firebase from "../firebase/firebase";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import useRedirect from "./useRedirect";
import splitbee from "@splitbee/web";
import templateFactoryContract from "../data/templateFactoryContract";
import getContractInstance from "../dapposInterface/helpers/getContractInstance";
import getAccounts from "../dapposInterface/helpers/getAccounts";

const useCreateDapp = (
  frontendStructure,
  dappName,
  contractAddress,
  contractAbi,
  contractName
) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const [redirect] = useRedirect();

  const deployAndCreate2 = async () => {
    try {
      const deployedContractAddress = "!!!!-13243434342--34-!!!!";

      frontendStructure.sections.forEach((section) => {
        section.blocks.forEach((block) => {
          console.log("getting here 1");
          block.items.forEach((item) => {
            console.log("getting here??");
            if (item.selectedFunc) {
              item.selectedFunc.contract.address = deployedContractAddress;
              console.log("????");
              item.config.contractsView.selectedContract.address =
                deployedContractAddress;
            }
          });
        });
      });
      console.log("frontendStructure:", frontendStructure);
    } catch (err) {
      console.log("err:", err);
    }
  };

  const deployAndCreate = async () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (!dappName) {
          throw {
            specific: "Missing required fields",
          };
        }

        const contractInstance = getContractInstance(
          templateFactoryContract.address,
          templateFactoryContract.abi,
          true
        );

        const accounts = await getAccounts();

        console.log("accounts:", accounts);

        const userAddress = accounts[0];

        console.log("userAddress:", userAddress);

        const res = await contractInstance.createNftContract(
          userAddress,
          "",
          "",
          "",
          ""
        );

        console.log("res:", res);

        const receipt = await res.wait();

        console.log("receipt", receipt);

        const deployedContractAddress =
          await contractInstance.getLastDeployedContract(userAddress);

        console.log("deployedContractAddress:", deployedContractAddress);

        frontendStructure.sections.forEach((section) => {
          section.blocks.forEach((block) => {
            block.items.forEach((item) => {
              if (item.selectedFunc) {
                item.selectedFunc.contract.address = deployedContractAddress;
                item.config.contractsView.selectedContract.address =
                  deployedContractAddress;
              }
            });
          });
        });

        console.log("______________________");

        const dappId = uuidv4();
        const dapp = {
          id: dappId,
          dappName: dappName,
          walletConnected: false,
          dappSaved: false,
          frontendStructure: frontendStructure,
          contracts: [
            {
              address: deployedContractAddress,
              abi: [
                {
                  inputs: [
                    {
                      internalType: "string",
                      name: "_name",
                      type: "string",
                    },
                    {
                      internalType: "string",
                      name: "_symbol",
                      type: "string",
                    },
                    {
                      internalType: "string",
                      name: "_initBaseURI",
                      type: "string",
                    },
                    {
                      internalType: "string",
                      name: "_initNotRevealedUri",
                      type: "string",
                    },
                  ],
                  stateMutability: "nonpayable",
                  type: "constructor",
                },
                {
                  anonymous: false,
                  inputs: [
                    {
                      indexed: true,
                      internalType: "address",
                      name: "owner",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "address",
                      name: "approved",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "Approval",
                  type: "event",
                },
                {
                  anonymous: false,
                  inputs: [
                    {
                      indexed: true,
                      internalType: "address",
                      name: "owner",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "address",
                      name: "operator",
                      type: "address",
                    },
                    {
                      indexed: false,
                      internalType: "bool",
                      name: "approved",
                      type: "bool",
                    },
                  ],
                  name: "ApprovalForAll",
                  type: "event",
                },
                {
                  anonymous: false,
                  inputs: [
                    {
                      indexed: true,
                      internalType: "address",
                      name: "previousOwner",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "address",
                      name: "newOwner",
                      type: "address",
                    },
                  ],
                  name: "OwnershipTransferred",
                  type: "event",
                },
                {
                  anonymous: false,
                  inputs: [
                    {
                      indexed: true,
                      internalType: "address",
                      name: "from",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "address",
                      name: "to",
                      type: "address",
                    },
                    {
                      indexed: true,
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "Transfer",
                  type: "event",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "to",
                      type: "address",
                    },
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "approve",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "owner",
                      type: "address",
                    },
                  ],
                  name: "balanceOf",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "baseExtension",
                  outputs: [
                    {
                      internalType: "string",
                      name: "",
                      type: "string",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "cost",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "getApproved",
                  outputs: [
                    {
                      internalType: "address",
                      name: "",
                      type: "address",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "owner",
                      type: "address",
                    },
                    {
                      internalType: "address",
                      name: "operator",
                      type: "address",
                    },
                  ],
                  name: "isApprovedForAll",
                  outputs: [
                    {
                      internalType: "bool",
                      name: "",
                      type: "bool",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "maxMintAmount",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "maxSupply",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "_mintAmount",
                      type: "uint256",
                    },
                  ],
                  name: "mint",
                  outputs: [],
                  stateMutability: "payable",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "name",
                  outputs: [
                    {
                      internalType: "string",
                      name: "",
                      type: "string",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "notRevealedUri",
                  outputs: [
                    {
                      internalType: "string",
                      name: "",
                      type: "string",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "owner",
                  outputs: [
                    {
                      internalType: "address",
                      name: "",
                      type: "address",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "ownerOf",
                  outputs: [
                    {
                      internalType: "address",
                      name: "",
                      type: "address",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "bool",
                      name: "_state",
                      type: "bool",
                    },
                  ],
                  name: "pause",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "paused",
                  outputs: [
                    {
                      internalType: "bool",
                      name: "",
                      type: "bool",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "renounceOwnership",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "reveal",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "revealed",
                  outputs: [
                    {
                      internalType: "bool",
                      name: "",
                      type: "bool",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "from",
                      type: "address",
                    },
                    {
                      internalType: "address",
                      name: "to",
                      type: "address",
                    },
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "safeTransferFrom",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "from",
                      type: "address",
                    },
                    {
                      internalType: "address",
                      name: "to",
                      type: "address",
                    },
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                    {
                      internalType: "bytes",
                      name: "data",
                      type: "bytes",
                    },
                  ],
                  name: "safeTransferFrom",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "operator",
                      type: "address",
                    },
                    {
                      internalType: "bool",
                      name: "approved",
                      type: "bool",
                    },
                  ],
                  name: "setApprovalForAll",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "string",
                      name: "_newBaseExtension",
                      type: "string",
                    },
                  ],
                  name: "setBaseExtension",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "string",
                      name: "_newBaseURI",
                      type: "string",
                    },
                  ],
                  name: "setBaseURI",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "_newCost",
                      type: "uint256",
                    },
                  ],
                  name: "setCost",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "string",
                      name: "_notRevealedURI",
                      type: "string",
                    },
                  ],
                  name: "setNotRevealedURI",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "_newmaxMintAmount",
                      type: "uint256",
                    },
                  ],
                  name: "setmaxMintAmount",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "bytes4",
                      name: "interfaceId",
                      type: "bytes4",
                    },
                  ],
                  name: "supportsInterface",
                  outputs: [
                    {
                      internalType: "bool",
                      name: "",
                      type: "bool",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "symbol",
                  outputs: [
                    {
                      internalType: "string",
                      name: "",
                      type: "string",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "index",
                      type: "uint256",
                    },
                  ],
                  name: "tokenByIndex",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "owner",
                      type: "address",
                    },
                    {
                      internalType: "uint256",
                      name: "index",
                      type: "uint256",
                    },
                  ],
                  name: "tokenOfOwnerByIndex",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "tokenURI",
                  outputs: [
                    {
                      internalType: "string",
                      name: "",
                      type: "string",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "totalSupply",
                  outputs: [
                    {
                      internalType: "uint256",
                      name: "",
                      type: "uint256",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "from",
                      type: "address",
                    },
                    {
                      internalType: "address",
                      name: "to",
                      type: "address",
                    },
                    {
                      internalType: "uint256",
                      name: "tokenId",
                      type: "uint256",
                    },
                  ],
                  name: "transferFrom",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "newOwner",
                      type: "address",
                    },
                  ],
                  name: "transferOwnership",
                  outputs: [],
                  stateMutability: "nonpayable",
                  type: "function",
                },
                {
                  inputs: [
                    {
                      internalType: "address",
                      name: "_owner",
                      type: "address",
                    },
                  ],
                  name: "walletOfOwner",
                  outputs: [
                    {
                      internalType: "uint256[]",
                      name: "",
                      type: "uint256[]",
                    },
                  ],
                  stateMutability: "view",
                  type: "function",
                },
                {
                  inputs: [],
                  name: "withdraw",
                  outputs: [],
                  stateMutability: "payable",
                  type: "function",
                },
              ],
              name: contractName,
            },
          ],
          images: [],
          config: { windowClick: false },
        };
        const db = firebase.firestore();

        const saveInitialDappStructure = await db
          .collection("users")
          .doc(user.uid)
          .collection("dapps")
          .doc(dappId)
          .set(dapp);

        const saveDappToActiveFrontends = await db
          .collection("activeFrontends")
          .doc(dappName)
          .set(dapp);

        redirect("/DappBuilder", { dapp: dapp });

        setLoading(false);
      } catch (err) {
        console.log("err:", err);
      }
    });

    // try {
    //   if (!dappName) {
    //     throw {
    //       specific: "Missing required fields",
    //     };
    //   }

    //   console.log(" selectedTemplate:", selectedTemplate);

    //   const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   const signer = provider.getSigner();

    //   selectedTemplate.contracts.forEach(async (contract) => {
    //     const factory = new ContractFactory(
    //       contract.abi,
    //       contract.byteCode,
    //       signer
    //     );

    //     console.log("factory:", factory);

    //     const deployedContract = await factory.deploy();

    //     console.log(deployedContract.address);
    //     console.log(deployedContract.deployTransaction);
    //   });
    // } catch (err) {
    //   console.log("err:", err);
    // }
  };

  const createDapp = async () => {
    console.log("heree??");
    setLoading(true);

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          if (!dappName || !contractAddress || !contractAbi || !contractName) {
            throw {
              specific: "Missing required fields",
            };
          }

          if (!ethers.utils.isAddress(contractAddress)) {
            throw {
              specific: "Invalid address",
            };
          }

          // if (typeof JSON.parse(contractAbi) !== "object") {
          //   throw {
          //     specific: "Invalid contract abi",
          //   };
          // }

          try {
            JSON.parse(contractAbi);
          } catch (err) {
            throw {
              specific: "Invalid contract abi",
            };
          }

          const dappId = uuidv4();
          const dapp = {
            id: dappId,
            dappName: dappName,
            walletConnected: false,
            dappSaved: false,
            frontendStructure: frontendStructure,
            contracts: [
              {
                address: contractAddress,
                abi: JSON.parse(contractAbi),
                name: contractName,
              },
            ],
            images: [],
            config: { windowClick: false },
          };
          const db = firebase.firestore();

          const saveInitialDappStructure = await db
            .collection("users")
            .doc(user.uid)
            .collection("dapps")
            .doc(dappId)
            .set(dapp);

          const saveDappToActiveFrontends = await db
            .collection("activeFrontends")
            .doc(dappName)
            .set(dapp);

          splitbee.track("Dapp created");

          redirect("/DappBuilder", { dapp: dapp });
          setLoading(false);
        } catch (err) {
          console.log("err:", err);
          setError(
            err.specific
              ? err.specific
              : "There was a problem. Please try again later or contact customer support"
          );

          setLoading(false);
        }
      } else {
        console.log("redirect to login");
      }
    });
  };

  return [createDapp, deployAndCreate, loading, error];
};

export default useCreateDapp;
