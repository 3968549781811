import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase from "../../../firebase/firebase";
import { useSelector, useDispatch } from "react-redux";
import { addBlock, toggleWindowClick } from "../../../redux/dapp";
import { populateDapp } from "../../../redux/dapp";
import DbSidebar from "../../../components/dappBuilder/dbContainer/dbSidebar/DbSidebar";
import DbRightArea from "../../../components/dappBuilder/dbRightAreaContainer/DbRightAreaContainer";
import DbTopBar from "../../../components/dappBuilder/dbTopBar/DbTopBar";
import DbContainer from "../../../components/dappBuilder/dbContainer/DbContainer";
import { isMobile } from "react-device-detect";
import "./remixIdeDappBuilder.css";
import MobileViewMessage from "../../../components/app/mobileViewMessage/MobileViewMessage";

export default function DappBuilder() {
  const db = firebase.firestore();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  const queryParams = new URLSearchParams(location.search);
  const uniqueId = queryParams.get("uniqueId");
  const dappName = queryParams.get("dappName");

  useEffect(() => {
    try {
      console.log("uniqueId:", uniqueId);
      console.log("dappName:", dappName);
    } catch (err) {
      console.log("err:", err);
    }
  }, []);

  const initiateWindowClick = (e) => {
    // console.log(e.target.className);
    if (
      e.target.className === "configContent" ||
      e.target.className === "modal-container" ||
      e.target.className === "modal-title" ||
      e.target.className === "modal-button-one" ||
      //
      e.target.className === "colorPicker-squareButton" ||
      e.target.className === "configStandardButton" ||
      e.target.className === "custom-file-input" ||
      e.target.className === "config-fontPickerButton" ||
      e.target.className === "assignedFunction-box" ||
      e.target.className === "config-modal-button" ||
      e.target.className === "assignedFunction-optionModalContainer-title" ||
      e.target.className.baseVal === "chevronCircle" ||
      e.target.className.baseVal === "backChevron" ||
      e.target.className.baseVal === "" ||
      e.target.className === "assignedFunction-optionModalContainer-title"
    ) {
      return;
    }

    dispatch(toggleWindowClick());
  };

  if (!isMobile) {
    return (
      <div className="dappBuilder" onClick={(e) => initiateWindowClick(e)}>
        <DbTopBar />
        <DbContainer />
      </div>
    );
  } else {
    return <MobileViewMessage />;
  }
}
