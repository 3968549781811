import React from "react";
import "./dbTopBar.css";
import { useSelector } from "react-redux";
import SaveButton from "./saveButton/SaveButton";
import AppButton from "../../app/appButton/AppButton";
import useSaveDapp from "../../../hooks/useSaveDapp";

export default function DbTopBar() {
  const reduxData = useSelector((state) => state.dapp);

  const [saveDapp, loading, error] = useSaveDapp(reduxData.dapp);

  return (
    <div className="dbTopBar">
      <AppButton
        label={"Save"}
        func={saveDapp}
        funcParams={[]}
        padding={"8px 25px"}
        loading={loading}
      />
    </div>
  );
}
