import React from "react";
import useAuth from "../../hooks/useAuth";
import googleSigninImage from "../../images/btn_google_signin_light_normal_web.png";
import firebase from "../../firebase/firebase";
import "./alternativeAuthContainer.css";
import useRedirect from "../../hooks/useRedirect";

export default function AlternativeAuthContainer({ inLogin }) {
  var provider = new firebase.auth.GoogleAuthProvider();

  const { signUpUser, loginUser, logoutUser, loading, error } =
    useAuth(provider);
  const [redirect] = useRedirect();

  return (
    <div className="alternativeAuthContainer">
      <div className="alternativeAuthContainer-text">Or</div>
      <div>
        <img
          className="alternativeAuth-button"
          onClick={signUpUser}
          src={googleSigninImage}
        />
      </div>
      {inLogin ? (
        <div
          className="alternativeAuthContainer-noAccountText"
          onClick={() => redirect("/SignUp")}
        >
          Don't have an account yet? Sign up
        </div>
      ) : (
        <div
          className="alternativeAuthContainer-noAccountText"
          onClick={() => redirect("/Login")}
        >
          Already have an account? Login
        </div>
      )}
    </div>
  );
}
