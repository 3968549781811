import React from "react";
import "./configButtonWithIcon.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import useRedirect from "../../../../../../hooks/useRedirect";

export default function ConfigButtonWithIcon({ label, Icon }) {
  const [redirect] = useRedirect();

  return (
    <button className="configButtonWithIcon" onClick={() => redirect("/")}>
      <IoMdArrowRoundBack />
      {label}
    </button>
  );
}
