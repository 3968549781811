import React from "react";
import "./appLogo.css";
import Logo from "../../../images/Logo.png";
import useRedirect from "../../../hooks/useRedirect";

export default function AppLogo() {
  const [redirect] = useRedirect();

  return <img className="appLogo" src={Logo} onClick={() => redirect("/")} />;
}
