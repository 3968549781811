import React from "react";
import { changeItemSelectedFuncPrice } from "../../../../../../redux/dapp";
import ConfigInput from "../configInput/ConfigInput";
import OptionsModal from "../optionsModal/OptionsModal";
import "./configItemFuncPrice.css";

export default function ConfigItemFuncPrice({ block, section, item }) {
  return (
    <div className="configItemFuncPrice">
      <ConfigInput
        label="Function Price in Eth"
        type="text"
        value={item.selectedFunc.price.priceValue}
        onChangeFunc={changeItemSelectedFuncPrice}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
        disabled={false}
      />

      {/* <OptionsModal
        label="Or set an input value as the function price"
        selectedOptionName="none"
        options={item.selectedFunc.inputs}
      /> */}
    </div>
  );
}
