import React from "react";
import DashboardDappContract from "./dashboardDappContract/DashboardDappContract";
import "./dashboardDappContractsContainer.css";

export default function DashboardDappContractsContainer({ dapp }) {
  const displayContracts = dapp.contracts.map((contract, idx) => {
    return <DashboardDappContract key={idx} dapp={dapp} contract={contract} />;
  });

  return (
    <div className="dashboardDappContractsContainer">
      {/* {dapp.contracts.length > 1 ? "Contracts" : "Contract"} */}
      {displayContracts}
    </div>
  );
}
