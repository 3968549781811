import { useState } from "react";
import firebase from "../firebase/firebase";
import { updateDappContracts } from "../redux/dapps";
import { useDispatch } from "react-redux";

const useDeleteDappContract = (dapp, selectedContract) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const deleteConfirmation = () => {
    if (window.confirm("Delete Contract?")) {
      deleteDappContract();
    }
  };

  const deleteDappContract = () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const db = firebase.firestore();
          const updatedContracts = [];

          dapp.contracts.map((contract) => {
            if (contract.address !== selectedContract.address) {
              updatedContracts.push(contract);
            }
          });

          const response = db
            .collection("users")
            .doc(user.uid)
            .collection("dapps")
            .doc(dapp.id)
            .update({
              contracts: updatedContracts,
            });

          dispatch(
            updateDappContracts({
              dappId: dapp.id,
              contracts: updatedContracts,
            })
          );

          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError("There was a problem deleting the dapp");
          console.log("err:", err);
        }
      } else {
        console.log("redirect to login");
      }
    });
  };

  return [deleteConfirmation, loading, error];
};

export default useDeleteDappContract;
