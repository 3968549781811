import React from "react";
import useAuth from "../../../hooks/useAuth";
import useRedirect from "../../../hooks/useRedirect";
import DashboardButton from "../../dashboard/dashboardButton/DashboardButton";
import AppDiscordIcon from "../appDiscordIcon/AppDiscordIcon";
import AppDocsText from "../appDocsText/AppDocsText";
import AppLogo from "../appLogo/AppLogo";
import "./appHeader.css";

export default function AppHeader() {
  const { logoutUser, userLoggedIn } = useAuth();

  const [redirect] = useRedirect();

  return (
    <div className="appHeader">
      <div className="appHeader-row">
        <AppLogo />
        <div className="appHeader-leftRow">
          <AppDocsText />
          <AppDiscordIcon size={20} />
          <DashboardButton
            label={userLoggedIn ? "Log out" : "Log in"}
            func={userLoggedIn ? logoutUser : redirect}
            funcParams={userLoggedIn ? [] : ["/Login"]}
            padding={"5px 20px"}
          />
        </div>
      </div>
    </div>
  );
}
