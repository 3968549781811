import React, { useState, useEffect } from "react";
import AlternativeAuthContainer from "../../components/alternativeAuthContainer/AlternativeAuthContainer";
import ErrorMessage from "../../components/form/errorMessage/ErrorMessage";

import "../../components/form/form.css";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import signUpUser from "../../helpers/auth/signUpUser";
import useAuth from "../../hooks/useAuth";

export default function SignUp() {
  const { signUpUser, loginUser, logoutUser, loading, error } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    passwordOne: "",
    passwordTwo: "",
  });

  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirected = urlParams.get("remixRedirected");
    if (redirected === "true") {
      setIsRedirected(true);
    }
  }, []);

  return (
    <div className="form-container">
      <div className="form">
        {isRedirected && (
          <p className="redirected-message">
            Start a 7 days free trial (no credit card required), your dapp will
            be saved to your account
          </p>
        )}

        <FormInput
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />
        <FormInput
          type="password"
          placeHolder={"Password"}
          value={formData.passwordOne}
          setStateValue={(password) =>
            setFormData({ ...formData, passwordOne: password })
          }
        />
        <FormInput
          type="password"
          placeHolder={"Confirm Password"}
          value={formData.passwordTwo}
          setStateValue={(password) =>
            setFormData({ ...formData, passwordTwo: password })
          }
        />
        <FormButton
          label="Sign up"
          func={signUpUser}
          funcParams={[formData.email, formData.passwordOne]}
          loading={loading}
          disabled={loading}
        />

        {error && <ErrorMessage message={error} />}
        <AlternativeAuthContainer />
      </div>
    </div>
  );
}
