import React from "react";
import useDownloadDapp from "../../hooks/useDownloadDapp";
import AppButton from "../../../../components/app/appButton/AppButton";
import ErrorMessage from "../../../../components/form/errorMessage/ErrorMessage";
import "./downloadDappContainer.css";

export default function DownloadDappContainer({ dapp }) {
  const [loading, downloadDappBuildFolder, updateMessage, error] =
    useDownloadDapp(dapp);

  return (
    <div className="downloadDappContainer">
      <div className="downloadDappContainer-title">
        Download {dapp.dappName}
      </div>
      <p className="downloadDappContainer-p">
        Download the Dapp's build folder and host your dapp anywhere you want.
      </p>
      <div className="downloadDappContainer-buttonContainer">
        <AppButton
          label={"Request Dapp Download"}
          func={downloadDappBuildFolder}
          funcParams={[]}
          padding={"8px 25px"}
          loading={loading}
        />
        {error && <ErrorMessage message={error} />}
        <div className="downloadDappContainer-updateMessage">
          {updateMessage}
        </div>
      </div>
    </div>
  );
}
