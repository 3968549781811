import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// const firebaseConfig =
//   process.env.NODE_ENV === "development"
//     ? {
//         apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
//         authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
//         projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
//         storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
//         messagingSenderId:
//           process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
//         appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
//         measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV,
//       }
//     : {
//         apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//         authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//         projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//         storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//         messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//         appId: process.env.REACT_APP_FIREBASE_APP_ID,
//         measurementId: process.env.REACT_APP_MEASUREMENT_ID,
//       };

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

export default firebase;
