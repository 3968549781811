import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeItemImage } from "../../redux/dapp";

const useConfigImage = (dispatchFuncParams) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const uploadImage = async (image) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", image);
      const resFile = await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data: formData,
        headers: {
          pinata_api_key: `${process.env.REACT_APP_PINATA_KEY}`,
          pinata_secret_api_key: `${process.env.REACT_APP_PINATA_SECRET}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const url = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;

      dispatch(
        changeItemImage({
          ...dispatchFuncParams,
          image: url,
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err:", err);
    }
  };

  return [uploadImage, loading];
};

export default useConfigImage;
