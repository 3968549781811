import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";

const useCheckUserPlan = () => {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const check = async () => {
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken(true);
          const decodedToken = await user.getIdTokenResult();
          setPlan(decodedToken.claims.stripeRole);
          console.log(
            "decodedToken.claims.stripeRole:",
            decodedToken.claims.stripeRole
          );
          setLoading(false);
        }
      });
    } catch (err) {
      setError("Error getting user plan");
      setLoading(false);
    }
  };

  useEffect(() => {
    check();
  }, []);

  return [plan, loading, error];
};

export default useCheckUserPlan;
