import React from "react";
import "./configRightItemRow.css";

export default function ConfigRightItemRow({
  Comp,
  func,
  funcParams,
  isReduxFunc,
  size,
}) {
  return (
    <div className="configRightItemRow">
      <Comp
        func={func}
        funcParams={funcParams}
        isReduxFunc={isReduxFunc}
        size={size}
      />
    </div>
  );
}
