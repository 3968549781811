import { v4 as uuidv4 } from "uuid";
import { GiThorHammer } from "react-icons/gi";

const customTemplate = {
  name: "Custom",
  Icon: GiThorHammer,
  contracts: [],
  frontendStructure: {
    sections: [
      {
        id: uuidv4(),
        backgroundColor: "#1b2129",
        config: {
          configOpen: true,
          configActive: true,
          selectedBlockConfig: "",
        },
        blocks: [],
      },
    ],
    font: {
      fontFamily: "Chakra Petch",
      config: { configOpen: true, configActive: true },
    },
    connectButton: { backgroundColor: "", textColor: "" },
  },
};

export default customTemplate;
