import React from "react";
import { BsSquare } from "react-icons/bs";
import { BsCheckSquare } from "react-icons/bs";
import { useDispatch } from "react-redux";

export default function CheckMark({ boolValue, func, funcParams }) {
  const dispatch = useDispatch();

  return (
    <>
      {boolValue ? (
        <BsCheckSquare
          onClick={() => {
            dispatch(func({ ...funcParams, toggleValue: false }));
          }}
        />
      ) : (
        <BsSquare
          onClick={() => {
            dispatch(func({ ...funcParams, toggleValue: true }));
          }}
        />
      )}
    </>
  );
}
