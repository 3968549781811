const subscriptionPlans = [
  {
    tier: "Premium",
    pricing: "$19.99",
    features: [
      "Up to 4 Dapps",
      "Up to 4 Contracts per Dapp",
      "Dapp hosting at app.dappos.io/yourDappName",
      "Unlimited Dapp downloads to host dapp anywhere",
    ],
  },
  {
    tier: "Ultimate",
    pricing: "$29.99",
    features: [
      "Up to 10 Dapps",
      "Up to 10 Contracts per Dapp",
      "Dapp hosting at app.dappos.io/yourDappName",
      "Unlimited Dapp downloads to host dapp anywhere",
      "Live chat customer support",
      "Remove branding",
    ],
  },
];

export default subscriptionPlans;
