import React from "react";
import SpinnerComponent from "../../app/spinnerComponent/SpinnerComponent";
import "./formButton.css";

export default function FormButton({ label, func, funcParams, loading }) {
  return (
    <button
      disabled={loading}
      className="formButton"
      onClick={() => {
        func(...funcParams);
      }}
    >
      {loading ? <SpinnerComponent size={25} color={"#1b2129"} /> : label}
    </button>
  );
}
