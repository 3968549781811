import React from "react";
import useRedirect from "../../../hooks/useRedirect";
import { IoSettingsSharp } from "react-icons/io5";
import "./settingsIcon.css";

export default function SettingsIcon() {
  const [redirect] = useRedirect();

  //   return <div className="settingsIcon">HELLO WORLD</div>;

  return (
    <IoSettingsSharp
      className="settingsIcon"
      size={25}
      onClick={() => redirect("/Settings")}
    />
  );
}
