import React from "react";
import { BsDiscord } from "react-icons/bs";

export default function AppDiscordIcon({ size }) {
  return (
    <BsDiscord
      size={size}
      style={{ marginRight: "25px", cursor: "pointer" }}
      onClick={() => {
        window.open("https://discord.com/invite/V2TmH8BBPy");
      }}
    />
  );
}
