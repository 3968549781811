import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  toggleBlockConfigActive,
  toggleFontConfigOpen,
  toggleSectionConfigActive,
  toggleSectionConfigOpen,
} from "../../../../redux/dapp";
import DownloadDappBuildFolder from "../../../app/downloadDappBuildFolder/DownloadDappBuildFolder";
import ConfigButtonWithIcon from "./configContainer/configButtonWithIcon/ConfigButtonWithIcon";
import ConfigContainer from "./configContainer/ConfigContainer";
import FontPicker from "./configContainer/fontPicker/FontPicker";
import BlockConfig from "./configContainer/sectionConfig//blockConfig/BlockConfig";
import ButtonItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/buttonItemConfig/ButtonItemConfig";
import ConnectButtonItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/connectButtonItemConfig/ConnectButtonItemConfig";
import DynamicContractDataItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/dynamicContractDataItemConfig/DynamicContractDataItemConfig";
import ImageItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/imageItemConfig/ImageItemConfig";
import SocialMediaIconsItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/socialMediaIconsItemConfig/SocialMediaIconsItemConfig";
import TextItemConfig from "./configContainer/sectionConfig/blockConfig/itemsConfig/textItemConfig/TextItemConfig";
import SectionConfig from "./configContainer/sectionConfig/SectionConfig";
import HelpQuestionMark from "../../../app/helpQuestionMark/HelpQuestionMark";
import openUrl from "../../../../helpers/openUrl";
import "./dbSidebar.css";
import DashboardButton from "../../../dashboard/dashboardButton/DashboardButton";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { appUrl } from "../../../../data/appUrls";
import useDappSaved from "../../../../hooks/useDappSaved";
import { IoFingerPrint } from "react-icons/io5";

export default function DbSidebar() {
  const reduxData = useSelector((state) => state.dapp);

  const [dappSaved] = useDappSaved();

  const displaySectionConfigs = reduxData.dapp?.frontendStructure.sections.map(
    (section, idx) => {
      if (section.config.configActive) {
        return (
          <ConfigContainer
            key={idx}
            section={section}
            ConfigComponent={SectionConfig}
            configName="section"
            configOpenToggleAction={toggleSectionConfigOpen}
            configOpenToggleActionParams={{ sectionId: section.id }}
            configOpen={section.config.configOpen}
            configActive={section.config.configActive}
          />
        );
      } else {
        return section.blocks.map((block, idx) => {
          if (block.id === section.config.selectedBlockConfig) {
            if (block.config.configActive) {
              return (
                <ConfigContainer
                  key={idx}
                  section={section}
                  block={block}
                  ConfigComponent={BlockConfig}
                  configName="block"
                  configOpenToggleAction={toggleSectionConfigOpen}
                  configOpenToggleActionParams={{
                    sectionId: section.id,
                    blockId: block.id,
                  }}
                  configActiveToggleAction={toggleSectionConfigActive}
                  configActiveToggleActionParams={{
                    sectionId: section.id,
                    selectedBlockConfig: "",
                  }}
                  configOpen={section.config.configOpen}
                  configActive={section.config.configActive}
                />
              );
            } else {
              return block.items.map((item, idx) => {
                if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "button"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={ButtonItemConfig}
                      configName="Button"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "connectButton"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={ConnectButtonItemConfig}
                      configName="Connect Button"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "largeText"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={TextItemConfig}
                      configName="Large Text"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "smallText"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={TextItemConfig}
                      configName="Small Text"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "image"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={ImageItemConfig}
                      configName="Image"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "dynamicContractData"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={DynamicContractDataItemConfig}
                      configName="Dynamic Contract Data"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                } else if (
                  item.id === block.config.selectedConfigItem.id &&
                  block.config.selectedConfigItem.type === "socialMediaIcons"
                ) {
                  return (
                    <ConfigContainer
                      key={idx}
                      section={section}
                      block={block}
                      item={item}
                      ConfigComponent={SocialMediaIconsItemConfig}
                      configName="Social Media Icons"
                      configOpenToggleAction={toggleSectionConfigOpen}
                      configOpenToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                      }}
                      configActiveToggleAction={toggleBlockConfigActive}
                      configActiveToggleActionParams={{
                        sectionId: section.id,
                        blockId: block.id,
                        item: item,
                      }}
                      configOpen={section.config.configOpen}
                      configActive={section.config.configActive}
                    />
                  );
                }
              });
            }
          }
        });
      }
    }
  );

  return (
    <div className="dbSidebar">
      <div className="sidebar-configContainers">
        {displaySectionConfigs}
        <ConfigContainer
          ConfigComponent={FontPicker}
          configName="Font Picker"
          configOpenToggleAction={toggleFontConfigOpen}
          configOpenToggleActionParams={{}}
          configOpen={reduxData.dapp?.frontendStructure.font.config.configOpen}
          configActive={
            reduxData.dapp?.frontendStructure.font.config.configActive
          }
        />
      </div>

      <div className="sidebar-bottom-container">
        <ConfigButtonWithIcon label="Dashboard" />

        {/* <HelpQuestionMark
          func={openUrl}
          funcParams={["https://documentation.dappos.io/"]}
          color={"#25272a"}
          backgroundColor={"white"}
          left={"25px"}
          bottom={"10px"}
        /> */}
      </div>

      {reduxData.dapp?.dappSaved && (
        <div
          className="sidebar-bottom-container-two"
          onClick={() =>
            window.open(`${appUrl.url}/${reduxData.dapp?.dappName}`)
          }
        >
          <div className="sidebar-bottom-container-two-text">
            View Dapp Site
          </div>
          <BsBoxArrowUpRight />
        </div>
      )}
    </div>
  );
}
