import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import useRedirect from "./useRedirect";

const useCheckTrial = () => {
  const [trialFinished, setTrialFinished] = useState(false);

  const [redirect] = useRedirect();
  const db = firebase.firestore();

  useEffect(() => {
    const fetch = async () => {
      try {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            const token = await user.getIdToken(true);
            const decodedToken = await user.getIdTokenResult();

            console.log(
              "decodedToken.claims.stripeRole:",
              decodedToken.claims.stripeRole
            );

            if (decodedToken.claims.stripeRole) {
              console.log("returning");
              return;
            }

            const docRef = await db
              .collection("users")
              .doc(user.uid)
              .collection("account")
              .doc("freeTrial");

            const result = await docRef.get();

            let date_1 = new Date();

            let modified = new Date(
              `${result.data().trialStartedOn.toDate()}`
            ).toLocaleDateString();

            let date_2 = new Date(modified);

            const days = (date_1, date_2) => {
              let difference = date_1.getTime() - date_2.getTime();
              let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
              return TotalDays;
            };

            console.log("Days in trial:", days(date_1, date_2));

            if (days(date_1, date_2) > 8) {
              setTrialFinished(true);
              redirect("/Pricing");
            }
          }
        });
      } catch (err) {
        console.log("err:", err);
      }
    };

    fetch();
  }, []);

  return [trialFinished];
};

export default useCheckTrial;
