import React from "react";
import { deleteItem } from "../../../../../../../../../redux/dapp";
import TrashCan from "../../../../../../../../icons/trashCan/TrashCan";
import ConfigRightItemRow from "../../../../configRightItemRow/ConfigRightItemRow";
import ImageUpload from "../../../../imageUpload/ImageUpload";

export default function ImageItemConfig({ section, block, item }) {
  return (
    <div>
      <ImageUpload section={section} block={block} item={item} />

      <ConfigRightItemRow
        Comp={TrashCan}
        func={deleteItem}
        funcParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
        isReduxFunc={true}
        size={15}
      />
    </div>
  );
}
