import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const dappsSlice = createSlice({
  name: "dapps",
  initialState: {
    dapps: [],
  },
  reducers: {
    populateDapps: (state, action) => {
      return {
        ...state,
        dapps: action.payload,
      };
    },
    updateDapps: (state, action) => {
      return {
        ...state,
        dapps: [
          ...state.dapps.filter((dapp) => dapp.id !== action.payload.dappId),
        ],
      };
    },
    updateDappContracts: (state, action) => {
      return {
        ...state,
        dapps: state.dapps.map((dapp) => {
          if (dapp.id === action.payload.dappId) {
            return {
              ...dapp,
              contracts: action.payload.contracts,
            };
          } else {
            return dapp;
          }
        }),
      };
    },
  },
});

export const { populateDapps, updateDapps, updateDappContracts } =
  dappsSlice.actions;

export default dappsSlice.reducer;
