import React from "react";
import AppHeader from "../../components/app/appHeader/AppHeader";
import PricingContainer from "../../components/app/pricingContainer/PricingContainer";
import "./pricing.css";

export default function Pricing() {
  return (
    <div className="pricing">
      <AppHeader />
      <PricingContainer />
    </div>
  );
}
