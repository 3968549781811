import React, { useState } from "react";
import "./formInput.css";

export default function FormInput({ type, placeHolder, value, setStateValue }) {
  const [inputValue, setInputValue] = useState("");

  return (
    <input
      type={type}
      className="formInput"
      placeholder={placeHolder}
      value={value}
      onChange={(e) => setStateValue(e.target.value)}
    />
  );
}
