import React from "react";
import useCheckUserPlan from "../../../hooks/useCheckUserPlan";
import useRedirect from "../../../hooks/useRedirect";
import SpinnerComponent from "../spinnerComponent/SpinnerComponent";
import "./downloadDappBubble.css";

export default function DownloadDappBubble({ dapp }) {
  const [plan, loading, error] = useCheckUserPlan();
  const [redirect] = useRedirect();

  const redirectFunc = () => {
    if (plan) {
      redirect("/DownloadDapp", { dapp: dapp });
    } else {
      redirect("/DownloadDapp", { dapp: dapp });

      // redirect("/Pricing");
    }
  };

  return (
    <div
      className="downloadDappBubble"
      onClick={redirectFunc}
      disabled={loading}
    >
      {loading ? (
        <SpinnerComponent size={25} color={"#1b2129"} />
      ) : (
        "Download Dapp"
      )}
    </div>
  );
}
