import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DbCanvas from "../../dapposInterface/components/dbCanvas/DbCanvas";
import {
  changeItemDynamicContractDataValueReturnValue,
  changeItemSelectedFuncInputValue,
  changeItemSelectedFuncPrice,
  clearItemSelectedFuncInputValues,
  populateDapposInterfaceDapp,
  toggleWalletConnected,
  updateDynamicContractDataItems,
} from "../../dapposInterface/redux/dapposInterfaceDapp";
import firebase from "../../firebase/firebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import DapposBrandingContainer from "../../components/app/dapposBrandingContainer/DapposBrandingContainer";
import useCheckUserPlan from "../../hooks/useCheckUserPlan";

export default function DapposFrontend() {
  const db = firebase.firestore();
  const dispatch = useDispatch();

  const [plan, loading, error] = useCheckUserPlan();

  useEffect(async () => {
    const url = window.location.href;
    const dappName = url.slice(url.lastIndexOf("/") + 1);
    console.log("dappName :", dappName);
    const docRef = db.collection("activeFrontends").doc(dappName);

    const result = await docRef.get();

    console.log("plan:", plan);

    dispatch(populateDapposInterfaceDapp(result.data()));
  }, []);

  const reduxDapposInterfaceDappData = useSelector(
    (state) => state.dapposInterfaceDapp
  );

  const interfaceFunctions = {
    changeItemSelectedFuncInputValue: changeItemSelectedFuncInputValue,
    changeItemSelectedFuncPrice: changeItemSelectedFuncPrice,
    clearItemSelectedFuncInputValues: clearItemSelectedFuncInputValues,
    updateDynamicContractDataItems: updateDynamicContractDataItems,
    changeItemDynamicContractDataValueReturnValue:
      changeItemDynamicContractDataValueReturnValue,
    toggleWalletConnected: toggleWalletConnected,
  };

  return (
    <>
      <DbCanvas
        inBuilder={false}
        dapp={reduxDapposInterfaceDappData.dapp}
        loadingDapp={reduxDapposInterfaceDappData.loadingDapp}
        reduxSlice="dapposInterfaceDapp"
        interfaceFunctions={interfaceFunctions}
      />
      {plan !== "ultimate" && <DapposBrandingContainer />}
    </>
  );
}
