import React from "react";
import DashboardButton from "../../dashboardButton/DashboardButton";
import "./dashboardDapp.css";
import DashboardDappContractsContainer from "./dashboardDappContractsContainer/DashboardDappContractsContainer";

import TrashCan from "../../../icons/trashCan/TrashCan";
import useDeleteDapp from "../../../../hooks/useDeleteDapp";
import SpinnerComponent from "../../../app/spinnerComponent/SpinnerComponent";
import { appUrl } from "../../../../data/appUrls";
import useRedirect from "../../../../hooks/useRedirect";
import useCheckUserPlan from "../../../../hooks/useCheckUserPlan";

export default function DashboardDapp({ dapp }) {
  const [deleteConfirmation, loading, error] = useDeleteDapp(dapp);

  const windowOpen = (url) => {
    window.open(url);
  };

  const [redirect] = useRedirect();
  const [plan, checkUserPlanLoading, checkUserPlanError] = useCheckUserPlan();

  return (
    <div className="dashboardDapp">
      <div className="dashboardDapp-row">
        {/* <div className="dashboardDapp-dappName">DappTutorial</div> */}
        <div className="dashboardDapp-dappName">
          {dapp.dappName.includes("TestDapp") ? "TestDapp" : dapp.dappName}
        </div>

        <div className="dashboardDapp-row-rightItem">
          {/* {dapp.contracts.length < 1 || plan === "premium" ? ( */}
          {dapp.contracts.length < 1 ? (
            <DashboardButton
              label={"+ Add Contract"}
              func={redirect}
              funcParams={["/AddContract", { dapp: dapp }]}
              padding={"6px 10px"}
            />
          ) : null}
        </div>
      </div>
      {dapp.dappName.includes("TestDapp") && (
        <div
          className="getting-started-guide-text"
          onClick={() => {
            window.open("https://documentation.dappos.io/");
          }}
        >
          Getting Started Guide
        </div>
      )}
      <DashboardDappContractsContainer dapp={dapp} />

      <div className="dashboardDapp-bottomRow">
        {loading ? (
          <SpinnerComponent size={25} color={"#2d6df8"} />
        ) : (
          <TrashCan func={deleteConfirmation} funcParams={[]} />
        )}

        <div className="dashboardDapp-bottomRow-rightItem">
          {/* <div className="dashboardDapp-bottomRow-itemOne">
            <DashboardButton
              label={`Launch at ${appUrl.url}/${dapp.dappName}`}
              func={windowOpen}
              funcParams={[`${appUrl.url}/${dapp.dappName}`]}
              padding={"6px 20px"}
            />
          </div> */}
          <DashboardButton
            label={"Edit Dapp"}
            func={redirect}
            funcParams={["/DappBuilder", { dapp: dapp }]}
            padding={"6px 20px"}
          />
        </div>
      </div>
    </div>
  );
}
