import React from "react";
import "./configLabelWithComp.css";

export default function ConfigLabelWithComp({ label, Comp }) {
  return (
    <div className="configLabelWithComp">
      <label>{label}</label>
      {Comp}
    </div>
  );
}
