import React, { useState } from "react";
import MobileViewMessage from "../../components/app/mobileViewMessage/MobileViewMessage";
import { isMobile } from "react-device-detect";

import FormButton from "../../components/form/formButton/FormButton";
import handleConnectContract from "../../helpers/handleConnectContract";
import FormText from "../../components/form/formText/FormText";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import { v4 as uuidv4 } from "uuid";

export default function PreConnectContract() {
  const testDappId = uuidv4();
  const testDappName = `NftTestContract(Rinkeby)-${uuidv4()}`;
  const testContractAddress = "0x488572f102F45630d885272a1ff05c3479EF7C64";
  const testContractAbi = [
    {
      inputs: [],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "getApproved",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "isApprovedForAll",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "quantity",
          type: "uint256",
        },
      ],
      name: "mint",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "num",
          type: "uint256",
        },
        {
          internalType: "string",
          name: "something",
          type: "string",
        },
        {
          internalType: "string",
          name: "word",
          type: "string",
        },
      ],
      name: "multipleInputs",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ownerOf",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "bytes",
          name: "_data",
          type: "bytes",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes4",
          name: "interfaceId",
          type: "bytes4",
        },
      ],
      name: "supportsInterface",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "tokenURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  const testContractFrontendStructure = {
    sections: [
      {
        id: uuidv4(),
        backgroundColor: "#9601f9",
        config: {
          configOpen: true,
          configActive: true,
          selectedBlockConfig: "",
        },
        blocks: [
          {
            id: uuidv4(),
            type: "block",
            backgroundColor: "#25272a",
            config: {
              configOpen: true,
              configActive: true,
              selectedConfigItem: {},
            },
            items: [
              {
                font: { fontFamily: "default" },
                id: uuidv4(),
                text: "Defi Sharks",
                textColor: "",
                type: "largeText",
              },
              {
                type: "button",
                buttonBackgroundColor: "#a337eb",
                buttonLabel: "Mint",
                buttonTextColor: "",
                config: { switchValueOne: false, switchValueTwo: false },
                errorMessage: "",
                font: { fontFamily: "default" },
                id: uuidv4(),
                name: "Button",
                selectedFunc: {
                  contract: {
                    address: testContractAddress,
                    abi: testContractAbi,
                  },
                  inputs: [
                    {
                      id: uuidv4(),
                      internalType: "uint256",
                      name: "quantity",
                      placeholder: "quantity",
                      type: "uint256",
                      value: "",
                    },
                  ],
                  name: "mint",
                  outputs: {
                    type: "uint256",
                    internalType: "uint256",
                    name: "",
                  },
                  price: 0,
                  stateMutability: "nonpayable",
                  type: "function",
                },
              },
              {
                config: { switchValueTwo: false, switchValueOne: false },
                font: { fontFamily: "" },
                id: uuidv4(),
                type: "connectButton",
              },
              {
                fontFamily: "default",
                icons: [
                  {
                    name: "discord",
                    url: "https://discord.com/",
                    id: "5260d4e2-6979-4fbe-b82f-7d0372a33550",
                    icon: "FaDiscord",
                  },
                  {
                    name: "telegram",
                    url: "https://telegram.org/",
                    id: "b6e6c7c1-a9c7-4023-82f2-aa12d485fc14",
                    icon: "BsTelegram",
                  },
                  {
                    name: "twitter",
                    url: "https://twitter.com/?lang=en",
                    icon: "BsTwitter",
                    id: "89361b21-115f-4557-856f-278fc67623ad",
                  },
                ],

                id: uuidv4(),
                type: "socialMediaIcons",
              },
            ],
          },
        ],
      },
    ],
    font: {
      config: { configOpen: true, configActive: true },
      fontFamily: "Bangers",
    },
    connectButton: { textColor: "", backgroundColor: "#a337eb" },
  };

  const [formData, setFormData] = useState({
    responseMessage: "",
    responseMessageActive: false,
    responseMessageType: null,
    loading: false,
  });

  if (isMobile) {
    return <MobileViewMessage />;
  }

  const redirectToConnectContract = () => {};

  const afterCallFunc = (responseMessageType, responseMessage) => {
    if (responseMessageType !== "error") {
      // history.push("/DappsDashboard");
      setFormData({ ...formData, loading: false });
    } else {
      setFormData({
        ...formData,
        loading: false,
        responseMessageActive: true,
        responseMessageType: responseMessageType,
        responseMessage: responseMessage,
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <FormButton
          label="Connect a Deployed Contract"
          func={redirectToConnectContract}
          funcParams={[]}
          afterCallFunc={afterCallFunc}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={false}
        />
        <FormText text="or" />

        <FormButton
          label="Use a test contract for now"
          func={handleConnectContract}
          funcParams={[
            testDappId,
            testDappName,
            testContractFrontendStructure,
            testContractAddress,
            testContractAbi,
            true,
            false,
            afterCallFunc,
          ]}
          afterCallFunc={afterCallFunc}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={false}
        />

        <FormText
          text="Don't have a deployed contract yet? Test Dappos using a test contract
          on the Rinkeby network."
        />

        <ResponseMessage
          message={formData.responseMessage}
          active={formData.responseMessageActive}
          messageType={formData.responseMessageType}
        />
      </div>
    </div>
  );
}
