import { useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import serverMainUrl from "../../../data/serverMainUrl";
import splitbee from "@splitbee/web";
import firebase from "../../../firebase/firebase";
import { v4 as uuidv4 } from "uuid";

const useDownloadDapp = (dapp) => {
  const [loading, setLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");
  const [error, setError] = useState(null);

  const [fetchedAgain, setFetchedAgain] = useState("");

  const db = firebase.firestore();

  const downloadDappBuildFolder = async () => {
    setLoading(true);
    console.log(">>>");

    try {
      const installModules = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "get",
        url: `${serverMainUrl}/installModules`,
      });

      console.log("installModules:", installModules);

      const writeToFile = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        url: `${serverMainUrl}/writeFile`,
        data: { dapp: dapp },
      });

      console.log("writeToFile:", writeToFile);

      const build = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "get",
        url: `${serverMainUrl}/build`,
        responseType: "blob",
      });

      console.log("build:", build);

      fileDownload(build.data, `${dapp.dappName}BuildFolder.zip`);
      setUpdateMessage("Download completed successfully!");

      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  const downloadDappBuildFolder2 = async () => {
    setLoading(true);

    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        const result = await db
          .collection("downloadRequests")
          .doc(`${dapp.dappName}-${uuidv4()}`)
          .set({
            dapp: dapp,
            requestedOn: new Date(),
            userEmail: user.email,
            userId: user.uid,
          });

        setLoading(false);
        setUpdateMessage(
          `Success! We'll be sending you your dapp's download folder to ${user.email} within 24 hours. You can also request a dapp download through our discord. Our discord: https://discord.com/invite/V2TmH8BBPy`
        );
      } catch (err) {
        console.log("err:", err);
        setError(
          "There was a problem downloading the Dapp. Please try again later or contact us through our Discord"
        );
        setLoading(false);
      }
    });
  };

  const downloadDappBuildFolder3 = async () => {
    setLoading(true);
    console.log(">>>");
    try {
      setUpdateMessage("Downloading Dapp... This can take a few minutes...");

      // const createApp = await axios({
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      //   method: "post",
      //   url: `${serverMainUrl}/createApp`,
      // });

      // console.log("React app created");

      const helloWorld = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "get",
        url: `${serverMainUrl}/`,
      });

      console.log("helloWorld:", helloWorld);

      // const writeToFile = await axios({
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      //   method: "post",
      //   url: `${serverMainUrl}/writeFile`,
      //   data: { dapp: dapp },
      // });

      // console.log("writeToFile:", writeToFile);

      const installModules = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "get",
        url: `${serverMainUrl}/installModules`,
      });

      console.log("installModules:", installModules);

      const build = await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "get",
        url: `${serverMainUrl}/build`,
        responseType: "blob",
      });

      console.log("build:", build);

      fileDownload(build.data, `${dapp.dappName}BuildFolder.zip`);
      setUpdateMessage("Download completed successfully!");
      setLoading(false);
    } catch (err) {
      setUpdateMessage("");
      setError(
        "There was a problem downloading the Dapp. Please try again later or contact us"
      );
      setLoading(false);
      console.log("err:", err);
    }
  };

  return [loading, downloadDappBuildFolder, updateMessage, error];
};

export default useDownloadDapp;
