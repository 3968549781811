import { useState } from "react";
import firebase from "../firebase/firebase";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import useRedirect from "./useRedirect";

const useAddContract = (dappId, contractAddress, contractAbi, contractName) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const [redirect] = useRedirect();

  const addContract = async () => {
    setLoading(true);

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          if (!contractName || !contractAddress || !contractAbi) {
            throw {
              specific: "Missing required fields",
            };
          }

          if (!ethers.utils.isAddress(contractAddress)) {
            throw {
              specific: "Invalid address",
            };
          }

          if (!JSON.parse(contractAbi)) {
            throw {
              specific: "Invalid contract abi",
            };
          }

          const db = firebase.firestore();

          const newContract = {
            address: contractAddress,
            abi: JSON.parse(contractAbi),
            name: contractName,
          };

          const updateDappContract = await db
            .collection("users")
            .doc(user.uid)
            .collection("dapps")
            .doc(dappId)
            .update({
              contracts: firebase.firestore.FieldValue.arrayUnion(newContract),
            });
          redirect("/");

          setLoading(false);
        } catch (err) {
          console.log("err:", err);
          setError(
            err.specific
              ? err.specific
              : "There was a problem. Please try again later or contact customer support"
          );

          setLoading(false);
        }
      } else {
        console.log("redirect to login");
      }
    });
  };

  return [addContract, loading, error];
};

export default useAddContract;
