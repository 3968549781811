import React from "react";
import AddContract from "../pages/addContract/AddContract";
import CreateDapp from "../pages/createDapp/CreateDapp";
import DapposFrontend from "../pages/dapposFrontend/DapposFrontend";
import DappBuilder from "../pages/dashboard/dappBuilder/DappBuilder";
import RemixIdeDappBuilder from "../pages/dashboard/remixIdeDappBuilder/RemixIdeDappBuilder";
import Dashboard from "../pages/dashboard/Dashboard";
import DownloadDapp from "../pages/downloadDapp/DownloadDapp";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import Login from "../pages/login/Login";
import PreConnectContract from "../pages/preConnectContract/PreConnectContract";
import Pricing from "../pages/pricing/Pricing";
import SignUp from "../pages/signUp/SignUp";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import TermsOfService from "../pages/termsOfService/TermsOfService";
import AccountSettings from "../pages/accountSettings/AccountSettings";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Settings" element={<AccountSettings />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsOfService" element={<TermsOfService />} />
        <Route path="/DownloadDapp" element={<DownloadDapp />} />
        <Route path="/PreConnectContract" element={<PreConnectContract />} />
        <Route path="/CreateDapp" element={<CreateDapp />} />
        <Route path="/AddContract" element={<AddContract />} />
        <Route path="/ChangeContract" element={<AddContract />} />
        <Route path="/DappBuilder" element={<DappBuilder />} />
        <Route path="/RemixIdeDappBuilder" element={<RemixIdeDappBuilder />} />
        <Route path="/:id" element={<DapposFrontend />} />
      </Routes>
    </Router>
  );
}
