import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import { useDispatch } from "react-redux";
import { populateDapps } from "../redux/dapps";
import useRedirect from "./useRedirect";

const useFetchUserDapps = () => {
  const dispatch = useDispatch();

  const [redirect] = useRedirect();

  const [dapps, setDapps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      try {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            const db = firebase.firestore();

            const querySnapshot = await db
              .collection("users")
              .doc(user.uid)
              .collection("dapps")
              .get();

            var contractTransferArr = [];
            querySnapshot.forEach((doc) => {
              contractTransferArr.push(doc.data());
            });

            dispatch(populateDapps(contractTransferArr));

            // console.log("user:", user);

            // console.log(
            //   "contractTransferArr:",
            //   JSON.stringify(contractTransferArr[0].frontendStructure)
            // );

            setLoading(false);
          } else {
            redirect("/Login");
          }
        });
      } catch (err) {
        setError(
          "There was an error getting the user's dapps, contact us through our Discord if this error keeps persisting"
        );
      }
    };

    fetch();
  }, []);

  return [loading, error];
};

export default useFetchUserDapps;
